import Axios from 'classes/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SIDELOAD_TYPES } from './reducer'


const exceptions = [400,404,500];

function callMessageContainer(message, status) {
  if(!exceptions.includes(status)) return;
  if(Number(status) === 400) toast.warn(message);
  if(Number(status) === 404) toast.warn(message);
  if(Number(status) === 500) toast.error(message);
}

export async function getProducts(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/workflow/deals/actionGetProducts/${dealId}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load products", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.PRODUCTS, data: results.data.result });
      console.info("loaded: products");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load products", 500);
      console.warn("failed to load products", ex);
  }
}

export async function getRemovals(dispatch, dealID) {
  try {
    const results = await Axios.get(`/entities/removals/?&$filter=dealID eq ${dealID}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load removals", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.REMOVALS, data: results.data.result });
      console.info("loaded: removals");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load removals", 500);
      console.warn("failed to load removals", ex);
  }
}

export async function getRemovalStores(dispatch, accountNo) {
  try {
    const results = await Axios.get(`/entities/stores/getSummaryAddress/${accountNo}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load removal stores", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.REMOVALSTORES, data: results.data.result });
      console.info("loaded: removal stores");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load removal stores", 500);
      console.warn("failed to load removal stores", ex);
  }
}

export async function getCrm(dispatch, oppNumber) {
  try {
    const results = await Axios.get(`/entities/workflow/deals/actionGetCRMData/${oppNumber}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load crm", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.CRM, data: results.data.result });
      console.info("loaded: crm");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load crm", 500);
      console.warn("failed to load crm", ex);
  }
}

export async function getCompatibles(dispatch) {
  try {
    const results = await Axios.get(`/sales/parts/compatible`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load compatibles", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.COMPATIBLES, data: results.data });
      console.info("loaded: compatibles");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load compatibles", 500);
      console.warn("failed to load compatibles", ex);
  }
}

export async function getDocuments(dispatch, oppNumber) {
  try {
    const results = await Axios.get(`/documents/opportunityDocs/${oppNumber}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load documents", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.DOCUMENTS, data: results.data });
      console.info("loaded: documents");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load documents", 500);
      console.warn("failed to load documents", ex);
  }
}

export async function getPSUsers(dispatch) {
  try {
    const results = await Axios.get(`/helpers/getProfessionalServicesUsers`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load ps users", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.PSUSERS, data: results.data });
      console.info("loaded: ps users");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load ps users", 500);
      console.warn("failed to load ps users", ex);
  }
}

export async function getDeviceNumbers(dispatch, dealID) {
  try {
    const results = await Axios.get(`/entities/serviceAdmin_salesDealsSelecteds/?&$filter=dealId eq ${dealID}`);
    const mapped = results.data.result.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } });
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load device numbers", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.DEVICENUMBERS, data: mapped });
      console.info("loaded: device numbers");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load device numbers", 500);
      console.warn("failed to load device numbers", ex);
  }
}

export async function getHostConfig(dispatch) {
  try {
    const results = await Axios.post(`/helpers/getHostConfig/`, { options: 1 });
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load host config", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.HOSTCONFIG, data: results.data });
      console.info("loaded: host config");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load host config", 500);
      console.warn("failed to load host config", ex);
  }
}

export async function getOtherDeals(dispatch, oppNumber) {
  try {
    const results = await Axios.get(`/sales/quotes/quotesByOpportunity?opportunityNo=${oppNumber}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load other deals for this opp", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.OTHERDEALS, data: results.data });
      console.info("loaded: other deals for this opp");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load other deals for this opp", 500);
      console.warn("failed to load other deals for this opp", ex);
  }
}

export async function updateDealData(dispatch, dealId, oppNumber) {
  try {
    const results = await Axios.patch(`/entities/workflow/deals/actionUpdateDeal/${dealId}/${oppNumber}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to update deal data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.UPDATEDEAL, data: results.data.result[0] });
      console.info("update: deal data");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to update deal data", 500);
      console.warn("failed to update deal data", ex);
  }
}

export async function getProcurmentData(dispatch) {
  try {
    const results = await Axios.get(`/entities/workflow/deals/actionGetProcurementData`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load procurement data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.PROCUREMENT, data: results.data.result });
      console.info("loaded: procurement");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load procurement data", 500);
      console.warn("failed to load procurement data", ex);
  }
}

export async function getDealPricing(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/workflow/deals/actionGetDealPricing/${dealId}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load pricing data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.DEALPRICING, data: results.data.result });
      console.info("loaded: pricing");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load pricing data", 500);
      console.warn("failed to load pricing data", ex);
  }
}

export async function getKeyContacts(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/serviceAdmin_salesDealsKeyContacts/?&$filter=dealID eq ${dealId}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load key contact data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.KEYCONTACT, data: results.data.result });
      console.info("loaded: key contacts");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load key contact data", 500);
      console.warn("failed to load key contact data", ex);
  }
}

export async function getNotesData(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/serviceAdmin_salesDealsNotes/?&$filter=dealID eq ${dealId}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to notes data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.NOTES, data: results.data.result });
      console.info("loaded: notes");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to notes data", 500);
      console.warn("failed to notes data", ex);
  }
}

export async function getSettlementData(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/settlements/?&$filter=dealId eq ${dealId}`);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load settlement data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.SETTLEMENT, data: results.data.result });
      console.info("loaded: settlements");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load settlement data", 500);
      console.warn("failed to load settlement data", ex);
  }
}

export async function getAddressData(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/addresses/?&$filter=dealId eq ${dealId} and addressType eq 0 `);
    if(exceptions.includes(results.status)) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load address data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ADDRESS, data: results.data.result });
      console.info("loaded: addresses");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load address data", 500);
      console.warn("failed to load address data", ex);
  }
}

export async function getInvoiceAddressData(dispatch, dealId) {
  try {
    const results = await Axios.get(`/entities/workflow/deals/actionGetInvoiceAddress/${dealId}`);
    if(exceptions.includes(results.status)) {
      callMessageContainer("failed to load invoice address data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.INVOICEADDRESS, data: results.data.result });
      console.info("loaded: invoice address");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load invoice address data", 500);
      console.warn("failed to load invoice address data", ex);
  }
}

export async function getEventDeclineType(dispatch) {
  try {
    const results = await Axios.get(`/entities/serviceAdmin_salesDeals2EventDeclineTypes`);
    if(exceptions.includes(results.status)) {
      callMessageContainer("failed to load decline type data", results.status);
    } else {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.DECLINETYPE, data: results.data.result });
      console.info("loaded: decline types");
    }
  } catch(ex) {
      dispatch({ type: SIDELOAD_TYPES.TYPE, name: SIDELOAD_TYPES.ERROR });
      callMessageContainer("failed to load decline type data", 500);
      console.warn("failed to load decline type data", ex);
  }
}