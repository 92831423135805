import { useState, useEffect, memo, Fragment } from "react";
import { permissionsObject } from "classes/permissions";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDateTime } from "classes/format";
import moment from 'moment'
import UILIB from "components";
import Axios from 'classes/axios';
import MIFLookupTable from "./mifLookup/mifLookup";
import MIFDrillDownTable from "./mifLookup/mifDrillDown";
import MIFExceptionsTable from "./mifLookup/mifExceptions";
import { statCard } from "classes/statsCard";

const RMBHub = memo(() => {
  const tabs = [
    { label: "All MIF", value: 0, context: "all" },
    { label: "All MIF by Customer", value: 1, context: "allMifCustomer" },
    { label: "Exceptions List", value: 2, context: "exceptions" },
  ];

  const location = useLocation();
  const history = useHistory();
  const permissions = useSelector((state) => state.permissions);
  const [currentTab, setCurrentTab] = useState(history.location.state ? history.location.state.tab : 0);
  const [topStats, setTopStats] = useState({ totalMif: 0, neverCommunicatedPercent: 0, monitoredVsTotal: 0, customersImpacted: 0, totalMonitoredMif: 0, monitoredNotCommunicated: 0, successfulCommunicationPercent: 0, totalDevicesImpacted: 0 });
  const [filter, setFilter] = useState({ button: '', accountNumber: '', groupCode: '' });
  const [selectedCard, setSelectedCard] = useState({});
  const [filterTable, setFilterTable] = useState('');
  const statContainerClass = "cardGeneric";
  const statChildClass = "cardGeneric cardChild cardSubHeading";

  useEffect(() => {
    const actionAsync = async () => {
      const quickStats = await Axios.get(`/rmb/getMifStats`);
      setTopStats({ ...topStats, ...quickStats.data[0] });
    }
    actionAsync();
  }, []);

  const handleCardClick = async (name) => {

    if(name === 'exceptionsListTotal') {
      setCurrentTab(2);
      setFilterTable('');
      resetFilters();
      return;
    }

    const newTableFilter = { ...filter };

    if (newTableFilter.button === name) {
      newTableFilter.button = '';
    } else {
      newTableFilter.button = name;
    }
    
    let currentTab;

    setFilterTable('equipment');
    currentTab = 0;

    setCurrentTab(currentTab);
    setFilter(newTableFilter);
  };

  const resetFilters = () => {
    setFilter({ button: '', accountNumber: '', groupCode: '' });
    setSelectedCard({});
  }

  const handleTabClick = (newID) => {
    setCurrentTab(newID);
    setFilterTable('');
    resetFilters();
  }

  const handleBackClick = () => {
    resetFilters();
    Object.keys(selectedCard).forEach(k => { selectedCard[k] = false });
    setSelectedCard(selectedCard);
  }

  return (
    <Fragment>
      <div className="flex-container row center width-100 pad-l5 pad-r5">
        <div className="flex-item row width-100 mar-t5 mar-l4">
          <p className="text-left">Last updated: {formatDateTime(moment(topStats.lastStatsDate).subtract(1, 'hour'), "DD/MM/YYYY HH:mm")}</p>
        </div>
        <div className="flex-item row width-100 mar-t5">
          {statCard(false, false, false, 'Total MIF', topStats.totalMif, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name))}
          {statCard(false, true, false, 'Never Communicated', topStats.neverCommunicated, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name), topStats.neverCommunicatedPercent + '%')}
          {statCard(false, true, false, 'Exceptions List Total', topStats.exceptionCount, statContainerClass, statChildClass, selectedCard, null, (name) => handleCardClick(name), (parseFloat(topStats.exceptionCount / topStats.totalMif) * 100).toFixed(2) + '%')}
          {statCard(false, false, false, 'Monitored MIF vs Total MIF', topStats.totalMonitoredMifPercent + '%', statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name))}
        </div>
        <div className="flex-item row width-100 mar-b10">
          {statCard(false, true, false, 'Total Monitored MIF', topStats.totalMonitoredMif, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name))}
          {statCard(false, true, false, 'Monitored MIF Not Communicated in Last 5 Days', topStats.totalDevices5DaysImpacted, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name), topStats.totalDevices5DaysImpactedPercent + '%')}
          {statCard(false, true, false, 'Total Devices Impacted', topStats.totalDevicesImpacted, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name))}
          {statCard(false, true, false, 'Monitored MIF Connectivity Rate', {data: topStats.successfulCommunicationMonitoredPercent + '%', description: 'Mon* MIF'}, statContainerClass, statChildClass, selectedCard, setSelectedCard, (name) => handleCardClick(name), null, {data: topStats.successfulCommunicationPercent + '%', description: 'Total MIF'})}
        </div>
        <div className="flex-item row width-100 mar-b10">
          {(!Object.values(selectedCard).some(x => x) && filter.button.length > 0) &&
            <UILIB.Button value="Back" onClick={() => handleBackClick()} className="mar-r5 mar-l5 grey" />}
        </div>
        <div className="flex-item center width-100 mar-t10 mar-b10">
          <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => handleTabClick(newId)} />
        </div>

        {((!Object.values(selectedCard).some(x => x) && filter.button.length === 0 && currentTab !== 0 && currentTab !== 2) || filterTable === 'customer') && <MIFLookupTable
          key={currentTab}
          context={tabs[currentTab]}
          filter={filter}
          setFilter={setFilter}
          route={location.pathname}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
        />}
        {(Object.values(selectedCard).some(x => x) || filter.button.length > 0 || currentTab === 0) && filterTable !== 'customer' && <MIFDrillDownTable
          context={tabs[currentTab]}
          filter={filter}
          route={location.pathname}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
          selectedCard={selectedCard}
        />}
        {(currentTab === 2) && filter.button.length === 0 && <MIFExceptionsTable
          context={tabs[currentTab]}
          filter={filter}
          route={location.pathname}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
        />}
      </div>
    </Fragment>
  );
});

export default RMBHub;
