import { memo } from 'react';

const TableSectionEmpty = memo(({ header }) => {
  return <tr className="row-hover">
    <td 
      style={{ height: '100px' }} 
      colSpan={header.length} 
      align="center">
      {'No Data Found'}
    </td>
  </tr>
})
export default TableSectionEmpty