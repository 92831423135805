import { useState, Fragment } from 'react';
import ViewNotes from './notesView';
import AddNotes from './notesAdd';
import EditNotes from './notesEdit';


export default function NotesDrawer({ entity, serialNumber, sync, isException }) 
{  
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState({noteId: '', note: '', show: false});

  return <Fragment>
    {!addNote && !editNote.show && <ViewNotes noteData={entity} serialNumber={serialNumber} setAddNote={setAddNote} setEditNote={setEditNote} sync={sync} isException={isException} />}
    {addNote && <AddNotes entity={entity} setAddNote={setAddNote} sync={sync} isException={isException} />}
    {editNote.show && <EditNotes entity={entity} editNote={editNote} setEditNote={setEditNote} sync={sync} />}
  </Fragment>
}