import { Fragment } from 'react';
import UILIB from 'components';
import { getUserCompanies, getUserSerials, getCompaniesByPostCode } from "classes/helpers";

export default function CustomerInventorySearch({ entityLookup }) {

  const handleApplyCustomerSearch = (data) => {
    const customerIds = data.filter(f => f.id).map(x => x.id);
    const customerNames = data.filter(f => f.AltName).map(x => x.AltName);
    entityLookup.set({ customerIds: customerIds, equipmentIds: null, customerNames: customerNames, context: 'customer' });
  }

  const handleApplyEquipmentSearch = (data) => {
    const equipmentIds = data.filter(f => f.id).map(x => x.id);
    entityLookup.set({ customerIds: null, equipmentIds: equipmentIds, context: 'equipment' });
  }

  const handleApplyPostCodeSearch = (data) => {
    const customerIds = data.filter(f => f.id).map(x => x.id);
    const customerNames = data.filter(f => f.AltName).map(x => x.AltName);
    const postCodes = data.filter(f => f.PostCode).map(x => x.PostCode);
    entityLookup.set({ customerIds: customerIds, postCodes: postCodes, equipmentId: null, customerNames: customerNames, context: 'postCode' });
  }

  return <Fragment>
      <div className="flex-container wrap width-100">
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search by Customer</div>
          <UILIB.AutoCompleteMulti
            className="min-height-50 max-width-400"
            name='customerSearch'
            blank='No Customers Found'
            query={getUserCompanies}
            enableButton={true}
            onClick={(data) => handleApplyCustomerSearch(data)} />
        </UILIB.Paper>
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search by Serial</div>
          <UILIB.AutoCompleteMulti
            className="min-height-50 max-width-400"
            name='equipmentId'
            width={'min-width-300'}
            blank="No Devices Found"
            query={getUserSerials}
            enableButton={true}
            onClick={(data) => handleApplyEquipmentSearch(data)} />
        </UILIB.Paper>
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search by Postcode</div>
          <UILIB.AutoCompleteMulti
            className="min-height-50 max-width-400"
            name='equipmentId'
            width={'min-width-300'}
            blank="No PostCodes Found"
            query={getCompaniesByPostCode}
            enableButton={true}
            onClick={(data) => handleApplyPostCodeSearch(data)} />
        </UILIB.Paper>
      </div>
  </Fragment>
}