import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Axios from 'classes/axios';
import UILIB from 'components';
import moment from 'moment';
import { createMultiSelectInput } from 'classes/controls/formControls';
import { consumableChip, statusChip, tableContainer, actionOpenDrawer, handleSerialClicked, getBGColour, getYesNo, setFilterVarsProcessedOrders } from '../functions';


export default function ProcessedOrdersTable({ serialNumber, regions, dates, setDates }) {
    const headerData = [
      { label: 'Toner #', value: 'tonerID', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
      { label: 'Customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
      { label: 'Model', value: 'machineName', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
      { label: 'Serial #', value: 'serialNumber', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Order Date', value: 'tonerDate', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Despatched Date', value: 'tonerDespatchedDate', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Requested', value: 'tonerTypeCast', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Source', value: 'source', type: 'string', filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Actioned By', value: 'processedByTxt', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Actioned At', value: 'processedAt', type: 'date', filtering: true, minWidth: 120, maxWidth: 120 },
      { label: 'Status', value: 'status', type: 'string', filtering: true, minWidth: 120 },
      { label: 'Notes', value: 'notes', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
      { label: 'Alerts', value: 'alerts', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
      { label: 'Logging', value: 'robot', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() }];

    const dispatch = useDispatch();
    const history = useHistory();
    const entityData = useRef({});
    const [selected, setSelected] = useState([]);
    const preferences = useSelector((state) => state.pagePreferences);
    const tablePageDefaults = { paging: { pages: [10,20,50,100,200], ...preferences.consumablesHub.paging } };
    const [localRender, setLocalRender] = useState(false);


    const updateDates = (newDate) => {
      setDates({ ...dates, [newDate.target.name] : newDate.target.value });
    }

    const handleRegionChanged = (selected) => {
      setSelected([...selected]);
    };

    async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          
          const sourceData = Array.from(new Set([row.fromAlertType, row.raisedBy])).join(' - ');

          const b_hasNotes = Boolean(row.notes && row.notes.length);
          const b_hasAlerts = Boolean((row.storeNote && row.storeNote.length) || (row.itemnote && row.itemnote.length));
          const b_hasRobotAlerts = Boolean((row.tonerRobotLog && row.tonerRobotLog.length));

          const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';
          const alertsColour = (b_hasAlerts) ? 'colour colour background-6 red' : 'colour lightGrey';

          const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
          const fn_alerts = actionOpenDrawer.bind(null, 'ViewAlertsDrawer', row, dispatch, b_hasAlerts);
          const fn_robotLogs = actionOpenDrawer.bind(null, 'ViewProcessedRobotDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });

          const bgColour = getBGColour(row);

          const actionedText = row.processedByTxt;
          const tonerDate = moment(row.tonerDate).utc().format("DD/MM/YYYY HH:mm:ss");
          const despatchedDate = (row.tonerDespatchedDate) ? moment(row.tonerDespatchedDate).utc().format("DD/MM/YYYY HH:mm:ss") : 'NA';
          const actionedDate = (row.processedAt) ? moment(row.processedAt).utc().format("DD/MM/YYYY HH:mm:ss") : 'NA';

          return {
            tonerID: { value: row.tonerID, raw: row.tonerID, class: bgColour },
            customerName: { value: row.customerName, raw: row.customerName, class: bgColour },
            machineName: { value: row.machineName, raw: row.machineName, class: bgColour },
            serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
            tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
            tonerDespatchedDate: { value: despatchedDate, raw: despatchedDate, class: bgColour },
            tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast, class: bgColour },
            source: { value: sourceData, raw: sourceData, class: bgColour },
            status: { value: statusChip(row.status, row, row.status), raw: row.status, class: bgColour },
            notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes },
            alerts: { value: tableContainer(fn_alerts, 'icon-bullhorn', (b_hasAlerts) ? 'View Alerts' : 'Alerts Unavailable', alertsColour, true), raw: b_hasAlerts },
            robot: { value: tableContainer(fn_robotLogs, 'icon-bullhorn', (b_hasRobotAlerts) ? 'View Robot Logs' : 'Robot Logs Unavailable', alertsColour, true), raw: row.tonerRobotLog },
            processedByTxt: { value: actionedText, raw: actionedText, class: bgColour },
            processedAt: { value: actionedDate, raw: actionedDate, class: bgColour }
          }
        })
        return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = ['storeNote', 'itemNote', 'notes', 'stockHoldingNote'];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const regionSelected = (selected.length) ? ` ServiceRegionId in ${selected.join(',')} ` : '';
      const serialFilter = (serialNumber) ? `and serialNumber eq ${serialNumber}` : '';

      const filterVars = setFilterVarsProcessedOrders(dates.startDate, dates.endDate);

      entityData.current = await Axios.get(`/entities/tonerOrders/getSummary/processedOrder/?&$filter=${[filterVars, regionSelected].filter(x => x).join(' and ')} ${serialFilter} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if(!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }

    return <Fragment>
        <UILIB.Paper className="width-100">
          <div className='flex-container row end mar-b10'>
            <div className='flex-item end'>
              <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="startDate" outerclassNname="flex-item align-center mar-r10" value={dates.startDate} onChange={updateDates} />
              <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="endDate" outerclassNname="flex-item align-center mar-r10" value={dates.endDate} onChange={updateDates} />
              {createMultiSelectInput('', 'selectRegion', false, selected, regions, 'All Regions', (ev) => handleRegionChanged(ev.target.value), null, null, null, null, null)}
            </div>
          </div>
          <UILIB.TableNew
            name={`ConsumablesProcessedOrders`}
            className='small height-100'
            overflowX='auto'
            overflowY='hidden'    
            header={headerData}
            remoteQuery={getTableData}
            remoteRender={[selected, dates]}
            defaultSettings={tablePageDefaults} />
        </UILIB.Paper>
      </Fragment>
}