import { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios';
import UILIB from 'components';
import DealLinkTableCell from '../../shared/dealLink';
import Modules from '../Modules';
import { setFilterVars, setDrawerFilterVars, actionOpenDrawer, getDefaultFilters, getInvoicedSelect, calcResetEnable, getDefaultSelected, getShowFilters, getRequiredField } from '../functions';

export default function InstallChecklistTable({ route, account, localPermissions, permissionsObject, permissions, location, dealId }) {

    const headerData = [
      { label: 'Order #', value: 'finalDealNo', type: 'number', filtering: true, minWidth: 80, maxWidth: 80 },
      { label: 'Customer', value: 'companyName', type: 'string', filtering: true },
      { label: 'Service Region', value: 'serviceRegion', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Manufacturer', value: 'manufacturer', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Model', value: 'description', type: 'string', filtering: true },
      { label: 'Part No', value: 'partNo', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
      { label: 'New/Used', value: 'newUsed', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
      { label: 'Warehouse', value: 'warehouse', type: 'string', filtering: true },
      { label: 'Ref', value: 'deviceNo', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
      { label: 'Deal Invoiced', value: 'dealInvoiceDate', type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Prov Invoiced', value: 'provInvoiceDate', type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Invoice #', value: 'invoiceNo', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Install Date', value: 'moveDate', type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Serial #', value: 'serialNo', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'PDI Complete', value: 'pdiCompleted', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Del Note Stored', value: 'signedDealNoteStoredTxt', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Contract #', value: 'contractNumber', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
      { label: 'kPAX Installed', value: 'kpaxInstalledTxt', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Status', value: 'status', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
      { label: 'Options', value: 'options', align: 'center', filtering: false, minWidth: 50, maxWidth: 50 }];
      
    const dispatch = useDispatch();
    const history = useHistory();
    const tablePageDefaults = { paging: { limit: 20, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
    const [pdiModalData, setPdiModalData] = useState({ show: false, entity: null });
    const [tableRender, setTableRender] = useState(0);
    const [filter, setFilter] = useState({ button: getDefaultSelected(location) });
    const [drawerFilter, setDrawerFilter] = useState(getDefaultFilters());
    const entityData = useRef([]);
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouseFilter, setWarehouseFilter] = useState([]);
    const permissionDealLink = permissionsObject('orderLink', localPermissions.current.permissions, permissions, location.pathname);
    const selectData = { selectData: getInvoicedSelect() };

    const requiredFieldsText = 'Columns highlighted in red are required to complete the build.'


    const handleButtonsChanged = (buttonName) => {
      const newTableFilter = { ...filter };
      if (newTableFilter.button === buttonName) {
          newTableFilter.button = '';
      } else {
          newTableFilter.button = buttonName;
      }
      setFilter(newTableFilter);
    };

    const handleWarehouseChange = (currentRegion) => {
      const actionAsync = async () => {
        setTableRender(tableRender === 0 ? 1 : 0);
      }
      if (currentRegion === "-1") {
        setWarehouseFilter([]);
      } else {
        setWarehouseFilter(currentRegion);
      }
      actionAsync();
    };

    const handleResetfilters = () => {
      setDrawerFilter(getDefaultFilters());
      setTableRender(!tableRender);
    };

    useEffect(() => {
      const actionAsync = async () => {
        const warehouseResult = await Axios.get(`/entities/warehouses`).then(api => api.data.result);
        const warehouseMapped = warehouseResult.map(x => { return { value: x.id, label: x.warehouse } });
        setWarehouseData(warehouseMapped);
      }
      actionAsync();
      // eslint-disable-next-line
    }, [])

    async function constructTable(sopApprovedData) {

      const tableData = sopApprovedData.map(row => {

        const pdiColour = (row.pdiCompleted) ? 'colour background green' : 'colour background red';
        const pdiCompleted = <UILIB.TableContainer data={row.pdiCompletedTxt} toolTip={row.pdiCompletedTxt} colour={pdiColour} />

        const options = <Modules.OptionsContainer row={row} dispatch={dispatch} setPdiModalData={setPdiModalData} render={tableRender} setRender={setTableRender} />

        const bgColour = 'colour background-2 red';

        return {
          finalDealNo: DealLinkTableCell(row, history, route, true, null, true),
          companyName: { value: row.companyName, raw: row.companyName },
          serviceRegion: { value: row.serviceRegion, raw: row.serviceRegion },
          manufacturer: { value: row.manufacturer, raw: row.manufacturer },
          description: { value: row.description, raw: row.description },
          partNo: { value: row.partNo, raw: row.partNo },
          newUsed: { value: row.newUsed, raw: row.newUsed },
          warehouse: { value: row.warehouse, raw: row.warehouse },
          deviceNo: { value: row.deviceNo, raw: row.deviceNo },
          serialNo: { value: row.serialNo, raw: row.serialNo, class: bgColour },
          pdiCompleted: { value: pdiCompleted, raw: row.pdiCompletedTxt },
          moveDate: { value: formatDateTime(row.moveDate, "DD/MM/YYYY"), raw: row.moveDate, class: bgColour },
          dealInvoiceDate: { value: formatDateTime(row.dealInvoiceDate, "DD/MM/YYYY"), raw: row.dealInvoiceDateConvert },
          provInvoiceDate: { value: formatDateTime(row.provInvoiceDate, "DD/MM/YYYY"), raw: row.provInvoiceDate, class: bgColour },
          invoiceNo: { value: row.invoiceNo, raw: row.invoiceNo, class: bgColour },
          signedDealNoteStoredTxt: { value: row.signedDealNoteStoredTxt, raw: row.signedDealNoteStoredTxt },
          contractNumber: { value: row.contractNumber, raw: row.contractNumber },
          status: { value: row.status, raw: row.status },
          kpaxInstalledTxt: { value: row.kpaxInstalledTxt, raw: row.kpaxInstalled },
          options : { value: options, raw: 0 }
        }
      })
      return tableData;
    }


    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = ['attachments', 'deal', 'singletons', 'address', 'installs', 'pdi'];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const warehouseFilterString = warehouseFilter.toString().length === 0 ? ' isn null ' : ` in ${warehouseFilter.toString()} `;
      const filterButtons = setFilterVars(filter, location, query);
      const calcFilters = setDrawerFilterVars(drawerFilter, getShowFilters(route, account));

     if (dealId === 0) {
        entityData.current = await Axios.get(`/entities/products/getSummaryInstallChecklist/?&$filter=warehouseId ${[warehouseFilterString, filterButtons, calcFilters].filter(x => x).join(' and ')} and isADevice eq 1 ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      } else {
        entityData.current = await Axios.get(`/entities/products/getSummaryInstallChecklist/?&$filter=dealID eq ${dealId} and warehouseId ${[warehouseFilterString, filterButtons, calcFilters].filter(x => x).join(' and ')} and isADevice eq 1 ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      }

      if(!entityData.current || !entityData.current.result.length) return false;

      entityData.current.result.forEach(x => { x.warehouses = warehouseData });

      return { tableData: await constructTable(entityData.current.result.filter(x => x.companyName !== 'Trade Copiers')), nonePaged: entityData.current.nonePaged };
    }

    return <Fragment>
        {pdiModalData.show && <Modules.DevicePDI 
          permissions={permissionDealLink}
          history={history} 
          pdiModalData={pdiModalData} 
          setPdiModalData={setPdiModalData} 
          isDisabled={pdiModalData.isDisabled} 
          sync={{ get: tableRender, set: setTableRender }} />}
        <UILIB.Paper className='width-100'>
          <div className='flex-container row center width-100 mar-b10'>
            <div className='flex-item flex-grow-1 start'>
              <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
              <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Complete' ? 'primary' : 'secondary')} name='Complete' value='Complete' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
              <div className="flex-container column end text-small colour text red text-11 mar-l2">&#9888; {requiredFieldsText}</div>
            </div>
            <div className='flex-item end min-width-300 align-center'>
              <UILIB.MultiSelect 
                className="flex-item align-center mar-l5" 
                disabled={!warehouseData.length}
                data={warehouseData} 
                placeholder="All Warehouses" 
                selected={warehouseFilter} 
                onChange={(ev) => { handleWarehouseChange(ev.target.value) }} />
              {getShowFilters(route, account) && <UILIB.Button disabled={calcResetEnable(drawerFilter)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />}
              {getShowFilters(route, account) && <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('InstallFiltersDrawer', { ...selectData, filters: drawerFilter, setFilters: setDrawerFilter }, null, dispatch, true, { get: tableRender, set: setTableRender }, '400px') }} />}
            </div>
          </div>
          <UILIB.TableNew
            name="InstallChecklist"
            className='small'
            overflowX='auto'
            overflowY='hidden'    
            header={headerData}
            remoteQuery={getTableData}
            remoteRender={[filter, tableRender]}
            defaultPaging={tablePageDefaults} />
        </UILIB.Paper>
    </Fragment>
}