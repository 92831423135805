import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { statCard } from '../../../../classes/statsCard'
import UILIB from "components";
import Axios from "classes/axios";
import BlockOrdersTable from "./blockOrdersTable";
import { useSelector } from "react-redux";
import { formatCurrency } from "classes/format";


export default function SalesManagerView() {
  const account = useSelector((state) => state.account);
  const history = useHistory();
  const [topStats, setTopStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [staff, setStaff] = useState(null);
  const statContainerClass = 'cardGeneric';
  const statChildClass = 'cardGeneric cardChild';


  const [tableFilter, setTableFilter] = useState({
    status: "active",
    staffIds:
      account.managerUsers &&
      account.managerUsers.length &&
      account.managerUsers.filter(f => f !== null).map((u) => u.azureId).concat([account.azureId]),
    staff: [],
    resetTableFilters: false,
    GoExport: false,
    ordered: false,
    closed: false
  });

  const resetTableFilter = () => {
    setTableFilter({
      status: "active",
      staffIds:
        account.managerUsers &&
        account.managerUsers.length &&
        account.managerUsers.map((u) => u.azureId).concat([account.azureId]),
      resetTableFilters: true,
      GoExport: false,
      ordered: false,
      closed: false
    });
  };


  useEffect(() => {
    if (account.managerUsers && account.managerUsers.length) {
      const me = { ...account }
      delete me.managerUsers
      const arr = account.managerUsers.filter(f => f !== null).concat([me]).map((a) => {
        a.color = a.userAvatarScheme;
        return a;
      });
      setStaff(arr);
    }
    getCounts()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCounts()
    // eslint-disable-next-line
  }, [tableFilter.staffIds])

  const getCounts = async () => {
    let query = '?'
    if (tableFilter.staffIds.length === 1) {
      query += 'staffId=' + tableFilter.staffIds[0]
    }
    Axios.get("/sales/deals/dealCounts" + query).then((counts) => {
      setTopStats(counts.data);
      setLoading(false);
    });
  }

  const handleFiltersChanged = (buttonName) => {
    const newTableFilter = { ...tableFilter };
    if (newTableFilter.status === buttonName) {
      newTableFilter.status = "";
    } else {
      newTableFilter.status = buttonName;
      switch(true) {
        case buttonName === "Active":
          newTableFilter.ordered = false;
          newTableFilter.closed = false; 
          break;
        case buttonName === "Ordered":
          newTableFilter.ordered = true;
          newTableFilter.closed = false; 
          break;
        case buttonName === "Closed":
          newTableFilter.ordered = false;
          newTableFilter.closed = true; 
          break;
        default: // Do nothing
      }
    }
    setTableFilter(newTableFilter);
  };


  const filterStaff = (staff) => {
    const filter = { ...tableFilter };
    if (filter.staffIds.length === 1 && filter.staffIds[0] === staff.azureId) {
      filter.staffIds = account.managerUsers.map((s) => s.azureId);
      filter.staff = []
      filter.isFiltered = false;

    } else {
      filter.staffIds = [staff.azureId];
      filter.staff = [staff];
      filter.isFiltered = true;
    }


    setTableFilter(filter);

  };

  if (!staff) return <div></div>;

  return <Fragment>
    <div className='flex-container row mar-l5 mar-r5 mar-t5'>
      <div className="col-xs-12 mar-b5">
        <UILIB.Button
          className="white outline"
          value="Back"
          iconBefore="icon-arrow-left"
          onClick={() => {
            history.push("/salesHub/accountmanagerview");
          }}
        />
      </div>
      <div className="flex-item row width-100 mar-t5 mar-b10">
        {statCard(false, false, false, 'Unapproved', (topStats.AwaitingApproval || 0), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Active Orders', (topStats.ApprovedAwaitingInvoice || 0), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Declined This Month', 'COMING SOON', statContainerClass + ' grey', statChildClass)}
        {statCard(false, false, false, 'Total Active GP', formatCurrency(topStats.GP), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Booked This Month', formatCurrency(topStats.bookedThisMonth), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Invoiced This Month', formatCurrency(topStats.ITM), statContainerClass, statChildClass)}
      </div>
      <div className="flex-container row wrap">
        <div className="flex-container row wrap between width-100 mar-b5">
          <div className="flex-item wrap start">
            <UILIB.Button
              className={ "mar-l5 mar-r5 mar-b5 mar-t5 small " +(!tableFilter.status || tableFilter.status === "quotes"? "primary": "secondary")}
              name="quotes"
              value="Quotes"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={
                "mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === "draft" ? "primary" : "secondary")}
              name="draft"
              value="Draft"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === "awaitingApproval" ? "primary" : "secondary")}
              name="awaitingApproval"
              value="Awaiting Approval"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === "active" ? "primary" : "secondary")}
              name="active"
              value="Active"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small  " + (!tableFilter.status || tableFilter.status === "invoiced" ? "primary" : "secondary")}
              name="invoiced"
              value="Invoiced"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small  " + (!tableFilter.status || tableFilter.status === "archived" ? "primary" : "secondary")}
              name="archived"
              value="Archived"
              onClick={(ev) => handleFiltersChanged(ev.target.name)}
            />
            <UILIB.Button
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small  primary"}
              name="declined"
              value="Declined"
              onClick={() => { }}
              disabled={true}
            />
            <UILIB.Button 
              className={"mar-l5 mar-r5 mar-b5 mar-t5 small secondary"}
              name="customerInventoryReport" 
              value="Customer Inventory Report" 
              onClick={() => history.push({ pathname: '/salesHub/customerInventoryReport' })} 
            />
            <UILIB.Button
              className={"mar-l20 mar-r5 mar-b5 mar-t5 small green"}
              name="resetFilters"
              value="Reset Filters"
              onClick={resetTableFilter}
            />
          </div>
          <div className="flex-item end mar-t5">
            {staff.map((user) => (
              <UILIB.Avatar
                className="mar-r5"
                key={"av_" + user.azureId}
                account={user}
                disabled={!tableFilter.staffIds.some((f) => f === user.azureId)}
                onClick={() => filterStaff(user)}
              />
            ))}
          </div>
        </div>
        <div className="flex-container row wrap width-100">
          <div className="flex-item center width-100">
            <BlockOrdersTable filter={tableFilter} staff={staff} loading={loading} />
          </div>
        </div>
        <div className="flex-container row between width-100 mar-t5 mar-b10">
          <div className="flex-item">
            <UILIB.Button
              className="mar-r5 primary"
              value="Sales Portfolio"
              onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/salesmasterdealsheet","_blank"); }}
            />
            <UILIB.Button
              className="primary"
              value="Refurb Kit for Sale"
              onClick={() => {
                window.open("https://360.agilico.co.uk/serviceadmin/sales/refurbkit/default.asp?pageFilter=1&storeFilter=0","_blank"); }}
            />
          </div>
          <div className="flex-item">
            <UILIB.Button
              className="mar-r5"
              value="Your Tasks"
              onClick={() => {
                window.open("https://360.agilico.co.uk/serviceadmin/deptheads/","_blank"); }}
            />
            <UILIB.Button value="Dashboards" onClick={() => { }} disabled={true} />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}
