import { useState, Fragment } from 'react';
import Modules from '../Modules';
import { createSelectInput } from 'classes/controls/formControls';


export default function MasterDealSummarySheetDrawer ({ data }) {

  const reportType = [
    { label: 'Master Deal Sheet Data', value: 1 },
    { label: 'Workflow Data', value: 2 }];

  const [selected, setSelected] = useState(1);

  return <Fragment>         
    <h2 className="mar-b20">Run Reports</h2>
    <div className="flex-container row start width-100 mar-3">
      {createSelectInput('Report Type', 'reportType', false, selected, reportType, (ev) => setSelected(Number(ev.target.value)), null, 'flex-grow-1 width-100 mar-b20')}
      {selected === 1 && <Modules.ReportAllData data={data} />}
      {selected === 2 && <Modules.ReportDeclinedData data={data} />}
    </div>
  </Fragment>   
}
