import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Axios from 'classes/axios';
import ManualOrderContainer from './manualOrderContainer';
import UILIB from 'components';
import moment from 'moment';
import { createMultiSelectInput } from 'classes/controls/formControls';
import { consumableChip, statusChip, tableContainer, setProcessedVars, actionOpenDrawer, handleSerialClicked, getBGColour, getYesNo, checkManualOrder, handleCustomerClicked } from '../functions'


export default function TonerProcessed({ context, topStats, setTopStats, regions }) {
    const headerData = [
      { label: 'Customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 370, maxWidth: 370 },
      { label: 'Model', value: 'machineName', type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
      { label: 'Serial #', value: 'serialNumber', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
      { label: 'Contract', value: 'sa_seDesc', type: 'string', filtering: true, minWidth: 190, maxWidth: 190 },
      { label: 'Order Date', value: 'tonerDate', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Requested', value: 'tonerTypeCast', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Actioned By', value: 'processedByTxt', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Actioned At', value: 'processedAt', type: 'date', filtering: true, minWidth: 120, maxWidth: 120 },
      { label: 'Source', value: 'source', type: 'string', filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Status', value: 'status', type: 'string', filtering: true, minWidth: 120 },
      { label: 'Notes', value: 'notes', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
      { label: 'Alerts', value: 'alerts', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() }];

    const dispatch = useDispatch();
    const history = useHistory();
    const entityData = useRef({});
    const pageContext = context.context;
    const [processedFilter, setProcessedFilter] = useState({ button: 'Processed' });
    const [localRender, setLocalRender] = useState(false);
    const [selected, setSelected] = useState([]);
    const [dates, setDates] = useState({
      startDate: moment().utc().format('YYYY-MM-DD 00:00').toString(), 
      endDate: moment().utc().format('YYYY-MM-DD 23:59').toString() });


    const updateDates = (newDate) => {
      setDates({ ...dates, [newDate.target.name] : newDate.target.value });
    }

    const handleButtonsChanged = (buttonName) => {
      const newTableFilter = { ...processedFilter };
      if (newTableFilter.button === buttonName) {
          newTableFilter.button = '';
      } else {
          newTableFilter.button = buttonName;
      }
      setProcessedFilter(newTableFilter);
    };

    const handleRegionChanged = (selected) => {
      setSelected([...selected]);
    };

    async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          
            const sourceData = Array.from(new Set([row.fromAlertType, row.raisedBy])).join(' - ');

            const b_hasNotes = Boolean(row.notes && row.notes.length);
            const b_hasAlerts = Boolean((row.storeNote && row.storeNote.length) || (row.itemnote && row.itemnote.length) || (row.stockHoldingNote && row.stockHoldingNote.length));

            const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';
            const alertsColour = (b_hasAlerts) ? 'colour colour background-6 red' : 'colour lightGrey';

            const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
            const fn_alerts = actionOpenDrawer.bind(null, 'ViewAlertsDrawer', row, dispatch, b_hasAlerts);

            const bgColour = getBGColour(row);

            const customerContainer = (checkManualOrder(row))
              ? <ManualOrderContainer data={row} history={history} />
              : handleCustomerClicked(row, history, null)

            const actionedText = row.processedByTxt;
            const tonerDate = moment(row.tonerDate).utc().format("DD/MM/YYYY HH:mm:ss");
            const actionedDate = moment(row.processedAt).utc().format("DD/MM/YYYY HH:mm:ss");

            return {
              customerName: { value: customerContainer, raw: row.customerName, class: bgColour },
              machineName: { value: row.machineName, raw: row.machineName, class: bgColour },
              serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
              sa_seDesc: { value: row.sa_seDesc, raw: row.sa_seDesc, class: bgColour },
              tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
              tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast, class: bgColour },
              source: { value: sourceData, raw: sourceData, class: bgColour },
              status: { value: statusChip(row.status, row, row.status), raw: row.status, class: bgColour },
              notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes },
              alerts: { value: tableContainer(fn_alerts, 'icon-bullhorn', (b_hasAlerts) ? 'View Alerts' : 'Alerts Unavailable', alertsColour, true), raw: b_hasAlerts },
              processedByTxt: { value: actionedText, raw: actionedText, class: bgColour },
              processedAt: { value: actionedDate, raw: actionedDate, class: bgColour }
            }
        })
        return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = (source === 'primary') ? ['storeNote', 'itemNote', 'notes', 'stockHoldingNote'] : [];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const processedOptions = setProcessedVars(processedFilter, dates.startDate, dates.endDate);
      const regionSelected = (selected.length) ? ` ServiceRegionId in ${selected.join(',')} ` : '';
      const select = ['tonerID', 'customerName', 'custName', 'custEmail', 'telNo', 'customerID', 'processedByTxt', 'processedAt', 'machineName', 'serialNumber', 'sa_inkey', 'sa_cukey', 'sa_seDesc', 'tonerDate', 'tonerType', 'tonerTypeCast', 'locationName', 'name', 'source', 'status', 'isManualOrder', 'fromAlertType', 'raisedBy', 'urgentOrder', 'over3Days'];

      if(context.context === 'chargable') expandArray.push('consumable');
      
      entityData.current = await Axios.get(`/entities/tonerOrders/getSummary/${pageContext}/?&$filter=${[processedOptions, regionSelected].filter(x => x).join(' and ')}${queryLocal}&$select=${select.join(' and ')}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      entityData.current.result.sort((a, b) => { return b.urgentOrder - a.urgentOrder });

      if(!entityData.current || !entityData.current.result.length) return false;

      setTopStats({ ...topStats, ...entityData.current.topStats });

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }

    return <Fragment>
        <UILIB.Paper className='width-100'>
            <div className='flex-container row mar-b10'>
              <div className='flex-item flex-grow-1 start wrap'>
                <UILIB.Button className={'mar-r5 small ' + (!processedFilter.button || processedFilter.button === 'Processed' ? 'primary' : 'secondary')} name='Processed' value='Processed' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
                <UILIB.Button className={'mar-l5 mar-r5 small ' + (!processedFilter.button || processedFilter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
              </div>
              <div className='flex-item end'>
                <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="startDate" outerclassNname="flex-item align-center mar-r10" value={dates.startDate} onChange={updateDates} />
                <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="endDate" outerclassNname="flex-item align-center mar-r10" value={dates.endDate} onChange={updateDates} />
                {createMultiSelectInput('', 'selectRegion', false, selected, regions, 'All Regions', (ev) => handleRegionChanged(ev.target.value), null, null, null, null, null)}
              </div>
            </div>
            <UILIB.TableNew
              name={`Consumables${pageContext}`}
              className='small'
              overflowX='auto'
              overflowY='hidden'    
              header={headerData}
              localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
              localRender={[localRender]}
              remoteQuery={getTableData}
              remoteRender={[selected, dates, processedFilter, context.context]} />
        </UILIB.Paper>
    </Fragment>
}