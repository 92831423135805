import { setConditionMessage } from '../formErrors/formErrors'
import { regexValidation } from '../../classes/dataValidation'

//Sales Hub -> blockOrderPage objects
export function getBlockOrderFormErrors() {
  const dataObj = {
    dmcPONo: { option: 0, subNav: 0, disabled: false, error: false },
    leaseorcash: { option: 0, subNav: 0, disabled: false, error: false },
    companyPaymentTerms: { option: 0, subNav: 0, disabled: false, error: false },
    leaseCompanyInfo: { option: 0, subNav: 0, disabled: false, error: false },
    companyPaymentLength: { option: 0, subNav: 0, disabled: false, error: false },
    leaseRate: { option: 0, subNav: 0, disabled: false, error: false },
    dealCurrency: { option: 0, subNav: 0, disabled: false, error: false },
    rvPercent: { option: 0, subNav: 0, disabled: false, error: false },
    rvIncluded: { option: 0, subNav: 0, disabled: false, error: false },
    rebateValue: { option: 0, subNav: 0, disabled: false, error: false },
    largerGroupSelect: { option: 0, subNav: 0, disabled: false, error: false },
    largerGroupId: { option: 0, subNav: 0, disabled: false, error: false },
    crmAddress: { option: 0, subNav: 0, disabled: false, error: false },
    serviceContract: { option: 0, subNav: 0, disabled: false, error: false },
    cpcLot: { option: 0, subNav: 0, disabled: false, error: false },
    organisation: { option: 0, subNav: 0, disabled: false, error: false },
    sector: { option: 0, subNav: 0, disabled: false, error: false },
    miniCompOrDirectBuy: { option: 0, subNav: 0, disabled: false, error: false },
    procurementLevel: { option: 0, subNav: 0, disabled: false, error: false },
    lease: { option: 0, subNav: 0, disabled: false, error: false }
  }
  return dataObj;
}

export function getBlockOrderAdditionalInfoErrors() {
  const dataObj = {
    "Install  Contact": { option: 0, subNav: 1, disabled: false, error: false, fields: ["contactName", "contactEmail", "contactPhone"] },
    "Service / Toner Contact": { option: 0, subNav: 1, disabled: false, error: false, fields: ["contactName", "contactEmail", "contactPhone"] },
    "IT Contact / KPAX Setup": { option: 0, subNav: 1, disabled: false, error: false, fields: ["contactName", "contactEmail", "contactPhone"] },
    "Key User": { option: 0, disabled: false, subNav: 1, error: false, fields: ["contactName", "contactEmail", "contactPhone"] },
    "Portal User": { option: 0, disabled: false, subNav: 1, error: false, fields: ["contactName", "contactEmail", "contactPhone"] },
    "Invoice Contact": { option: 0, disabled: false, subNav: 1, error: false, fields: ["contactName", "contactEmail", "contactPhone"] }
  }
  return dataObj;
}

export function getBlockOrderDocumentErrors() {
  const dataObj = { documents: { option: 0, disabled: false, subNav: 2, error: false, fields: [] } }
  return dataObj;
}

export function getBlockOrderInvoiceErrors() {
  const dataObj = { crmAddress: { option: 0, subNav: 0, disabled: false, error: false } }
  return dataObj;
}

export function getAddNewProductDrawerFormErrors() {
  const dataObj = {
    productClass: { option: 0, subNav: 0, disabled: false, error: false },
    productType: { option: 0, subNav: 0, disabled: false, error: false },
    partNo: { option: 0, subNav: 0, disabled: false, error: false }
  }
  return dataObj;
}

export function getEditContactsDrawerFormErrors(props) {
  return {
    contactName: { subNav: 0, option: 1, condition: (props?.companyName?.length < 2), messageText: 'Please enter a Contact Name', disabled: false, error: false },
    contactEmail: { subNav: 0, option: 1, condition: (props?.customerFirstName?.length < 2), messageText: 'Please enter a Contact Email', disabled: false, error: false },
    contactPhone: { subNav: 0, option: 1, condition: (props?.customerLastName?.length < 1), messageText: 'Please enter a Contact Phone', disabled: false, error: false }
  }
}

export function getEditEquipmentDrawerFormErrors(formData, leaseTypes, equipmentRemoval) {
  const dataObj = {
    model: { option: 1, condition: (!formData.model || String(formData.model).length < 3), messageText: 'Please enter a Model Description', subNav: 0, disabled: false, error: false },
    serial: { option: 1, condition: (!formData.serial || String(formData.serial).length < 3), messageText: 'Please enter a Serial Number', subNav: 0, disabled: false, error: false },
    currentLocationAddress: { option: 1, condition: (Number(formData.currentLocationAddress) < 1), messageText: 'Please enter the Current Location', subNav: 0, disabled: false, error: false },
    equipmentOwnership: { option: 1, condition: (Number(formData.equipmentOwnership) < 1), messageText: 'Please enter Equipment Ownership', subNav: 0, disabled: false, error: false },
    equipmentOwnerDetails: { option: 1, condition: (!formData.equipmentOwnerDetails || String(formData.equipmentOwnerDetails).length < 3), messageText: 'Please enter Equipment Ownership Details', subNav: 0, disabled: (!leaseTypes.has(Number(equipmentRemoval.equipmentOwnership))), error: false },
    returnMethod: { option: 1, condition: (Number(formData.returnMethod) < 1), messageText: 'Please enter Return Method', subNav: 0, disabled: false, error: false },
    locationOnsite: { option: 1, condition: (!formData.locationOnsite || String(formData.locationOnsite).length < 3), messageText: 'Please enter Location Onsite', subNav: 0, disabled: false, error: false }
  }
  return dataObj;
}

export function getRemoveDevicesAdditionalDrawerFormErrors(formData) {
  const dataObj = {
    model: { option: 1, condition: (!formData.model || String(formData.model).length < 3), messageText: 'Please enter a Model Description', subNav: 0, minLength: 3, disabled: false, error: false },
    serial: { option: 1, condition: (!formData.serial || String(formData.serial).length < 3), messageText: 'Please enter a Serial Number', subNav: 0, disabled: false, error: false },
    currentLocationAddress: { option: 1, condition: (Number(formData.currentLocationAddress) < 1), messageText: 'Please enter the Current Location', subNav: 0, disabled: false, error: false },
    equipmentOwnership: { option: 1, condition: (Number(formData.equipmentOwnership) < 1), messageText: 'Please enter Equipment Ownership', subNav: 0, disabled: false, error: false },
    equipmentOwnerDetails: { option: 1, condition: (!formData.equipmentOwnerDetails || String(formData.equipmentOwnerDetails).length < 3), messageText: 'Please enter Equipment Ownership Details', subNav: 0, disabled: false, error: false },
    returnMethod: { option: 1, condition: (Number(formData.returnMethod) < 1), messageText: 'Please enter Return Method', subNav: 0, disabled: false, error: false },
    locationOnsite: { option: 1, condition: (!formData.locationOnsite || String(formData.locationOnsite).length < 3), messageText: 'Please enter Location Onsite', subNav: 0, disabled: false, error: false }
  }
  return dataObj;
}

export function getSettlementAdditionalDrawerFormErrors(formData) {
  const dataObj = {
    agreementNumber: { subNav: 0, option: 1, condition: (!formData.agreementNumber || String(formData.agreementNumber).length < 3), messageText: 'Please enter an Agreement Number', disabled: false, error: false },
    agreementType: { subNav: 0, option: 1, condition: (!formData.agreementType || String(formData.agreementType).length < 3), messageText: 'Please select an Agreement Type', disabled: false, error: false },
    agreementProvider: { subNav: 0, option: 1, condition: (Number(formData.agreementProvider) === -1), messageText: 'Please enter an Agreement Provider', disabled: false, error: false },
    settlementHow: { subNav: 0, option: 1, condition: (Number(formData.settlementHow) === -1), messageText: 'Please enter a Settlement Method', disabled: false, error: false }
  }
  return dataObj;
}


export function getAddAddressFormErrors(formData) {
  const dataObj = {
    customerName: { subNav: 0, option: 1, condition: (!formData.customerName || formData.customerName.length < 3), messageText: 'Please enter a longer company name', disabled: false, error: false },
    address1: { subNav: 0, option: 1, condition: (!formData.address1 || formData.address1.length < 1), messageText: 'Please enter a longer address', disabled: false, error: false },
    postcode: { subNav: 0, option: 1, condition: (!formData.postcode || formData.postcode.length < 3), messageText: 'Please enter a longer postcode', disabled: false, error: false }
  }
  return dataObj;
}



//device collection 
export function getDeviceCollectFormErrors() {
  const dataObj = {
    moveDate: { subNav: 0, option: 1, messageText: 'Please enter an install date', disabled: false, error: false }
  }
  return dataObj;
}

export function getDeviceCollectConsumablesFormErrors(props) {
  const dataObj = {
    tonerCode: { subNav: 0, option: 1, condition: props.tonerCode.length < 1, messageText: 'Please enter a Toner Code', disabled: false, error: false },
    cyan: { subNav: 0, option: 1, condition: props.cyan.length < 1, messageText: 'Please enter a cyan value', disabled: false, error: false },
    magenta: { subNav: 0, option: 1, condition: props.magenta.length < 1, messageText: 'Please enter a magenta value', disabled: false, error: false },
    yellow: { subNav: 0, option: 1, condition: props.yellow.length < 1, messageText: 'Please enter a yellow value', disabled: false, error: false },
    black: { subNav: 0, option: 1, condition: props.black.length < 1, messageText: 'Please enter a black value', disabled: false, error: false },
    wasteBox: { subNav: 0, option: 1, condition: props.wasteBox.length < 1, messageText: 'Please enter a wasteBox value', disabled: false, error: false },
    staples: { subNav: 0, option: 1, condition: props.staples.length < 1, messageText: 'Please enter a staples value', disabled: false, error: false }
  }
  return dataObj;
}




//device dashboard
export function getDeviceMovementFormErrors(formData) {
  const dataObj = {
    collectionAddress: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    collectionEmail: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    collectionName: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    collectionTelephone: { ption: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    companyName: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    companyEmail: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    companyTelephone: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    contactEmailNetwork: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    contactNameNetwork: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    contactTelephoneNetwork: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    courier: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    delAddress: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    delContactEmail: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    delContactName: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    delContactTelephone: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    delMethod: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    moveDate: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    salesPerson: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    serviceRegion: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false },
    logisticsPoCId: { option: 0, disabled: formData.processStage > 3, error: false },
    psOther: { option: 0, subNav: 0, disabled: formData.processStage > 3, error: false}
  }
  return dataObj;
}

export function getDeviceMovementQuoteErrors(formData) {
  const dataObj = {
    companyEmailAddress: { option: 0, subNav: 2, disabled: !formData.processStage > 2, error: false },
    companyContactName: { option: 0, subNav: 2, disabled: !formData.processStage > 2, error: false },
    totalValue: { option: 0, subNav: 2, disabled: !formData.processStage > 2, error: false },
    companyEmailNotes: { option: 0, subNav: 2, disabled: !formData.processStage > 2 , error: false },
    quoteValueNotes: { option: 0, subNav: 2, disabled: !formData.processStage > 2, error: false }
  }
  return dataObj;
}



//Parts Management 
export function getAddProductFormErrors(productData) {
  const dataObj = {
    name: { option: 0, disabled: false, subNav: 0, error: false },
    partNumber: { option: 0, disabled: false, subNav: 0, error: false },
    manufacturerPartNumber: { option: 0, disabled: false, subNav: 0, error: false },
    listPrice: { condition: productData && productData.listPrice && productData.listPrice.length < 1, option: 0, disabled: false, subNav: 0, error: false },
    partnerPrice: { condition: productData && productData.partnerPrice && productData.partnerPrice.length < 1, option: 0, disabled: false, subNav: 0, error: false },
    basePrice: { condition: productData && productData.basePrice && productData.basePrice.length < 1, option: 0, disabled: false, subNav: 0, error: false },
    specialPrice: { condition: productData && productData.specialPrice && productData.specialPrice.length < 1, option: 0, disabled: false, subNav: 0, error: false },
    colourBasePrice: { option: 0, disabled: false, subNav: 0, error: false },
    monoBasePrice: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartManufacturerId: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartRanges: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartTypeId: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartCategoryId: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartVoTypeId: { option: 0, disabled: false, subNav: 0, error: false },
    SalesPartSupplierId: { option: 0, disabled: false, subNav: 0, error: false },
    serviceMeters: { option: 0, disabled: false, subNav: 0, error: false } 
  }
  return dataObj;
}



//logistic pdi base entity
export function getDevicePDIFormErrors(props) {
  const serialCondition = setConditionMessage(
    [(props.serialNo) ? String(props.serialNo).length < 1 : true, (!regexValidation('alphaNumericPattern', props.serialNo))],
    ['Please enter a serial number','Please enter a serial number without spaces']
  )
  const dataObj = {
    warehouse: { subNav: 0, option: 1, condition: props.warehouse && Number(props.warehouse) === -1, messageText: 'Please select a warehouse', disabled: false, error: false },
    serialNo: { subNav: 0, option: 1, ...serialCondition, disabled: false, error: false }
  }
  return dataObj;
}



//consumables hub
export function getCustomerRequestErrors(props) {
  const dataObj = {
    notes: { subNav: 0, option: 1, condition: props.notes.length < 1, messageText: 'Notes field cannot be empty, please add some notes and try again', disabled: false, error: false }
  }
  return dataObj;
}

export function getAddNewGroup(props) {
  const dataObj = {
    groupName: { subNav: 0, option: 1, condition: props.groupName.length < 1, messageText: 'Please enter a Group Name', disabled: false, error: false },
    useHvToner: { subNav: 0, option: 1, condition: props.useHvToner === -1, messageText: 'Please select Toner Type', disabled: false, error: false }
  }
  return dataObj;
}

export function getProcessVo(formErrors, sendToVo) {
  const getCondition = (data, type) => { 
    if(type === 'type') return Boolean(Number(data) === -1);
    if(type === 'courier') return Boolean(Number(data) === -1);
  }
  Object.keys(formErrors.current).forEach(x => {
    const error = formErrors.current[x];
    const split = x.split('-');
    if(split.length === 2) {
      formErrors.current[x] = { ...error, condition: getCondition(sendToVo.consumables[split[0]][split[1]], split[1]) }; }
  })
  return formErrors.current = { 
    ...formErrors.current, 
    courier: { condition: getCondition(sendToVo.courier, 'courier'), option: 0 } };
}

export function getSubmitConsumablesErrors(props) {
  return {
    FullName: { subNav: 0, option: 1, condition: (props && props.FullName) && props.FullName.length < 1, messageText: 'Please enter a contact name', disabled: false, error: false },
    PhoneNumber: { subNav: 0, option: 1, condition: (props) && props.PhoneNumber === -1, messageText: 'Please enter a contact number', disabled: false, error: false },
    EmailAddress: { subNav: 0, option: 1, condition: (props && props.EmailAddress) && props.EmailAddress.length < 1, messageText: 'Please enter a contact email', disabled: false, error: false },
    urgentOrder: { subNav: 0, option: 1, condition: (props) && props.useHurgentOrdervToner === -1, messageText: 'Please select if urgent order', disabled: false, error: false },
    focJit: { subNav: 0, option: 1, condition: (props) && props.useHvToner === -1, messageText: 'Please select if request is FOC JIT', disabled: false, error: false }
  }
}

//sop hub
export function getSopNoteErrors(props) {
  const dataObj = {
    theNotes: { subNav: 0, option: 1, condition: props.theNotes.length < 1, messageText: 'Notes field cannot be empty, please add some notes and try again', disabled: false, error: false }
  }
  return dataObj;
}

export function getSendLogisticsErrors(props) {
  props.current.devices = props.current.devices.map(x => {  
    return { ...x, errored: { ...x.errored, option: 1, condition: (x.warehouse.length < 1 || Number(x.warehouse) === -1), messageText: 'Please select from list' } } });
  return props.current.devices.map(x => x.errored);
}



//rmb hub
export function getRMBNoteErrors(props) {
  const dataObj = {
    note: { subNav: 0, option: 1, condition: props.note.length < 1, messageText: 'Reason / Note field cannot be empty, please add a reason / note and try again', disabled: false, error: false }
  }
  return dataObj;
}

//rd hub
export function getRDNoteErrors(props) {

  const dataObj = {
    notes: { subNav: 0, option: 1, condition: props.theNotes.length < 1, messageText: 'Notes field cannot be empty, please add some notes and try again', disabled: false, error: false }
  }
  return dataObj;
}

//portal hub 
export function getRequestPortalErrors(props) {
  return {
    customerFirstName: { subNav: 0, option: 1, condition: (props?.firstName?.length < 2), messageText: 'Please enter a First Name', disabled: false, error: false },
    customerLastName: { subNav: 0, option: 1, condition: (props?.lastName?.length < 2), messageText: 'Please enter a Last Name', disabled: false, error: false },
    customerEmail: { subNav: 0, option: 1, condition: (props?.customerEmail?.length < 1), messageText: 'Please enter an Email Address', disabled: false, error: false },
    companyName: { subNav: 0, option: 1, condition: (props?.companyName?.length < 1), messageText: 'Please enter a Company Name', disabled: false, error: false },
    accountNumber: { subNav: 0, option: 1, condition: (props?.accountNumber?.length < 1), messageText: 'Please enter an Account Number', disabled: false, error: false }
  }
}

export function getEditRequestPortalErrors(props) {
  return {
    companyName: { subNav: 0, option: 1, condition: (props?.companyName?.length < 2), messageText: 'Please enter a Company Name', disabled: false, error: false },
    customerFirstName: { subNav: 0, option: 1, condition: (props?.customerFirstName?.length < 2), messageText: 'Please enter a First Name', disabled: false, error: false },
    customerLastName: { subNav: 0, option: 1, condition: (props?.customerLastName?.length < 1), messageText: 'Please enter a Last Name', disabled: false, error: false }
  }
}

export function getRequestPortaNotesErrors(props) {
  const dataObj = {
    notes: { subNav: 0, option: 1, condition: props.theNotes.length < 1, messageText: 'Notes field cannot be empty, please add some notes and try again', disabled: false, error: false }
  }
  return dataObj;
}

export function getDeclineDealErrors(props) {
  const otherSelected = props.select.some(x => Number(x) === 5);
  return {
    notes: { subNav: 0, option: 1, condition: Boolean(otherSelected && (props?.notes?.length < 5)), messageText: `Notes can't be empty when selecting Other`, disabled: false, error: false }
  }
}