import UILIB from 'components';
import { setDrawer } from 'store/actions';
import ViewNotesDrawer from './drawer/viewNotesDrawer';
const moment = require('moment');
const todayMinusFive = moment().subtract(5, 'days').utc().format('YYYY-MM-DD');

export function getYesNo() {
    return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }]
}

const normaliseNoteData = (noteData, source) => {
  if (!noteData || !Array.isArray(noteData)) {
    return [];
  }

  return noteData.map(note => {
    if (source === "VO") {
      return {
        id: note.Id,
        equipmentId: note.ParentId,              
        text: note.Text,
        loggedBy: note.LoggedBy,
        modifiedBy: note.ModifiedBy,
        loggedDate: note.LoggedDate,
        modifiedDate: note.ModifiedDate,
        noteSource: source
      };
    } else if (source === "MIF") {
      return {
        id: note.id,
        equipmentId: note.id,             
        text: note.note,
        loggedBy: note.createdByTxt,
        modifiedBy: note.updatedByTxt,
        loggedDate: note.createdAt,
        modifiedDate: note.updatedAt,
        noteSource: source
      };
    }
    else {
      return {};
    }
  });
}

export async function actionOpenNotesDrawer(type, entity, dispatch, enabled, sync, isException) {
  let noteData;

  if(entity.notes && entity.notes !== 0 && entity.notes.length > 0) {
    noteData = normaliseNoteData(entity.notes, "MIF")
  }
  else if(entity.voNotes && entity.voNotes !== 0 && entity.voNotes.length > 0) {
    noteData = normaliseNoteData(entity.voNotes, "VO")
  }

  if(!enabled) return false;

  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} isException={isException} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: "600px",
    content: selector(type, noteData)
  }))
}

export function actionOpenExceptionsDrawer(type, entity, dispatch, enabled, sync) {
    if(!enabled) return false;
    const selector = (type, data) => {
      if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    };
    dispatch(setDrawer({ 
      open: true, 
      width: "600px",
      content: selector(type, entity)
    }))
}

export function tableContainer(onClick, icon, toolTip, colour, hover, hasVoNotes) {
    return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function setFilterVars(context, filter) {
    if(filter.button === 'accountNumber') {
        return ` (accountNo like ${filter.accountNumber}) `;
    }
    if(filter.button === 'totalMif') {
        return ``;
    }
    if(filter.button === 'totalCustomersImpacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'ricohRemoteImpacted') {
      return `mifException eq 0 and (lastRemoteDate isn null and lastRemoteDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'konicaCsrcImpacted') {
      return `mifException eq 0 and (lastKonicaDate isn null and lastKonicaDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'canonEMainImpacted') {
      return `mifException eq 0 and (lastEmDate isn null and lastEmDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'monitoredMifConnectivityRate') {
      return `mifException eq 0 and (lastScannedAnyDate gt ${todayMinusFive}) `;
    }
    if(filter.button === 'totalDevicesImpacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'kpaxImpacted') {
      return `mifException eq 0 and (lastKpaxDate isn null and lastKpaxDate lte ${todayMinusFive} and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'siteAuditImpacted') {
      return `mifException eq 0 and (lastSiteAuditDate isn null and lastSiteAuditDate lte ${todayMinusFive} and lastKpaxDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'hpWjaImpacted') {
      return `mifException eq 0 and (LastHpDate isn null and LastHpDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'groupCode') {
      return ` (groupCode eq ${filter.groupCode}) `;
    }
    if(context === 'impacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `; 
    }
    else if(context === 'neverCommunicated') {
      return `(neverCommunicated ne 0 or neverCommunicated is null) `;
    }
  }

  export function rowSelected(event, row, setSelectedRows, page) {
    const isChecked = event.target.checked;
    const rowId = row['id'];
    const equipmentId = row['equipmentId'];
    const reason = row['reason'];
    const hasVoNotes = Boolean(row.voNotes && row.voNotes.length);;
    const isException = page === 'exceptionsList' ? true : row.mifException;

    setSelectedRows(prevState => ({
      ...prevState,
      [rowId]: {
        isChecked,
        hasVoNotes,
        isException,
        equipmentId,
        reason
      }
    }));
  };

  export function selectAllRows(event, entityData, setSelectedRows) {
    const isChecked = event.target.checked;

    setSelectedRows(prevState => {
      const newSelectedRows = {};

      entityData.current.result.forEach(row => {
        newSelectedRows[row['id']] = {
          isChecked,
          hasVoNotes: prevState[row['id']]?.hasVoNotes || false,
          isException: prevState[row['id']]?.isException ?? row.mifException,
          equipmentId: prevState[row['id']]?.equipmentId ?? row.equipmentId,
          reason: prevState[row['id']]?.reason ?? row.reason
        };
      });

      return newSelectedRows;
    });
  }

  export function checkRow (id, entityData, selectedRows, voOnly) {
    const item = entityData.current.result.find(item => String(item['id']) === String(id));

    if(voOnly) {
      return Object.values(selectedRows).some(selectedRow => selectedRow.isChecked && selectedRow.hasVoNotes);
    }

    return item?.notes?.some(note => note.NoteSource === 'Exception') || 
      Object.values(selectedRows).some(selectedRow => selectedRow.isChecked && selectedRow.hasVoNotes);
  };

  export function getSelectedRowIds(selectedRows) {
    return Object.entries(selectedRows)
      .filter(([id, row]) => row.isChecked && row.isException)
      .map(([id, row]) => ({
          id: Number(id),
          equipmentId: Number(row.equipmentId),
          noteText: row.reason,
          hasVoId: row.hasVoNotes
      }));
  }

  export function getSelectedNoteIds(selectedEntities) {
    const selectedNoteIds = [...new Set(selectedEntities
      .filter(entity => entity.voNotes && entity.voNotes.length > 0)
      .flatMap(entity => entity.voNotes)
      .map(voNote => voNote.Id))];

    return selectedNoteIds;
  }