import Modules from '../Modules';
import { useEffect, useState, useRef, memo } from 'react';
import { filterEngine, getCancelToken, calcLocalRender, calcPageSelect, setCacheData, appendSelected } from '../functions';
import axios from 'axios';


const TableBodyLocal = memo(({ 
  header, 
  render, 
  setRender, 
  localQuery, 
  localRender = [], 
  remoteQuery, 
  remoteRender = [], 
  moduleRender, 
  setModuleRender, 
  tableData, 
  setLoading, 
  settings, 
  checked, 
  setChecked,
  selectQuery }) => 
{ 

  const [tableDataLocal, setTableDataLocal] = useState([]);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const cancelToken = useRef(axios.CancelToken);
  const source = useRef(null);

  useEffect(() => {    
    if(!localQuery) return;
    const actionAsync = async () => { 
      const queryResult = await localQuery(tableDataRaw);
      settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, ...calcPageSelect(settings, queryResult.length) }});
      calcLocalRender(settings, tableDataLocal, queryResult, tableData, render, setRender);
      setModuleRender(!moduleRender);
      setTableDataLocal(queryResult);
    }
    actionAsync();
  }, [...localRender])

  useEffect(() => {
    if(!remoteQuery) return;
    const timer = setTimeout(async () => {
      try {
        setLoading(true);
        const dataResult = await filterEngine(remoteQuery, settings, true, getCancelToken(source, cancelToken));
        tableData.current = (dataResult) ? dataResult.tableData : []; 
        appendSelected(tableData.current, header, checked, setChecked);
        settings.current = setCacheData(settings.current);
        setTableDataLocal((dataResult) ? dataResult.tableData : []);
        setTableDataRaw((dataResult) ? dataResult.raw : []);
        setModuleRender(!moduleRender);
        setLoading(false);
      }
      catch (err) {
        if(err !== 'ERR_CANCELED') console.log(err);
        if(err !== 'ERR_CANCELED') setLoading(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [...remoteRender, render])


  return <tbody>
    {Boolean(tableDataLocal.length)
      ? tableDataLocal.map((row, rowIndex) => { 
        return (
          <Modules.DataRowContainer
            header={header} 
            row={row}
            render={render}
            moduleRender={moduleRender}
            setModuleRender={setModuleRender}
            settings={settings}
            checked={checked}
            setChecked={setChecked}
            selectQuery={selectQuery}
            rowIndex={rowIndex} /> 
          )
        })
      : <Modules.DataRowContainerEmpty header={header} />}
  </tbody>
})
export default TableBodyLocal