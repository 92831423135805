import { useEffect, useRef, Fragment } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { permissionDecider } from 'classes/permissions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import Modules from './Modules';

export default function ViewQuote(props) {

  const history = useHistory();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const localPermissions = useRef({});
  const permissions = useSelector((state) => state.permissions);
  const permissionsData = useRef([{ name: 'salesHub', hubName: 'Sales Hub', routeName: location.pathname }]);


  useEffect(() => {
    const actionAsync = async () => { 

      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      await props.permissions.generalAccess();

      if(history.action !== 'PUSH') return history.replace({ newQuote: false, message: {} });
      if (history.location.state && history.location.state.message && history.location.state.newQuote) {
        toast.info(`Quote #${history.location.state.message.dealNumber} successfully added for ${history.location.state.message.crmOwner}`);
      } else if (history.location.state && history.location.state.message && history.location.state.message.showPermissionMessage) {
        toast.info('You do not have permission to view this Quote');
      } else if (history.location.state && history.location.state.message && history.location.state.message.duplicate === true) {
        toast.warning(`A Quote for Opportunity #${history.location.state.message.opportunityNumber.trim()} already exists!`);
      } else if (history.location.state && history.location.state.message && history.location.state.message.error) {
        toast.error(`Failed to create quote: ${history.location.state.message.response}`);
      }
    }
    actionAsync();
  }, [])
  

  return <Fragment>
    <div className="flex-container column height-100 mar-t10 mar-r5 mar-l5">
      <UILIB.Paper className='flex-item row width-100 mar-b20'>
        <div className='flex-item start width-100 mar-b20'><h3>Your Quotes</h3></div>
        <div className='flex-item start flex-grow-1'>
          <UILIB.Button value="Back" className="grey" onClick={() => history.push('/salesHub/accountmanagerview')} />
        </div>
      </UILIB.Paper>
      <Modules.QuotesTable 
        history={history}
        account={account} />
    </div>
  </Fragment>
}