import UILIB from 'components';
import { useState, Fragment } from 'react'
import Axios from 'classes/axios';


export default function OrderSubmitModal({ ourDeal, history, onCancel }) 
{

  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';


  const actionArchiveOrder = async () => {
    setSaving(true);
    try { 
      const result = await Axios.post(`/entities/workflow/deals/actionArchiveDeal/${ourDeal.dealID}`, ourDeal);
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        if(result.status === 409) setBannerError({ error: true, message: versionError });
      } else {
        history.push('/sopHub');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  };


  return <Fragment>
    <UILIB.MessageBox 
      header={'Archive deal'} 
      loading={saving}
      text={'Click OK to ARCHIVE this Deal'} 
      onConfirm={async () => await actionArchiveOrder()} 
      onCancel={() => onCancel()} 
      errored={bannerError} />
  </Fragment>
}