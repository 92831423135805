import { useState, useEffect, useRef, memo, Fragment } from 'react';
import { getLogisticsStatus, getRegions, getUsers, getUsersByGroup } from "classes/helpers";
import { permissionsObject, permissionDecider } from 'classes/permissions';
import { statCard } from 'classes/statsCard';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import UILIB from 'components';
import Axios from 'classes/axios'
import { getDefaultFilters, getDefaultReportFilters, getTabContext, actionOpenDrawer } from './functions';
import AwaitingApprovalTable from './tables/awaitingApprovalTable';
import ApprovedTable from './tables/approvedTable';
import InstallChecklistTable from '../logisticsHub/tables/installChecklistTable';


const SopHub = memo((props) => {

  const tabs = [
    { label: "Awaiting Approval", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Install Checklist", value: 3 }];
  
  const dispatch = useDispatch();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const localPermissions = useRef({});
  const permissions = useSelector((state) => state.permissions);
  const permissionsData = useRef([{ name: 'sopHub', hubName: 'Sop Hub', routeName: location.pathname }]);
  const [topStats, setTopStats] = useState({ avgMonth: 0, awaitingApproval: 0, awaitingApprovalOverSla: 0, activeOrders: 0, myActiveOrders: 0, myActiveOrdersOverSLA: 0 });
  const [currentTab, setCurrentTab] = useState(2);
  const defaultFilters = getDefaultFilters(location);
  const [filters, setFilters] = useState(defaultFilters);
  const defaultReportFilters = getDefaultReportFilters();
  const [reportFilters, setReportFilters] = useState(defaultReportFilters);
  const [staff, setStaff] = useState([]);
  const [sopStaff, setSopStaff] = useState([]);
  const [regions, setRegions] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(true);
  const statContainerClass = 'cardGeneric';
  const statChildClass = 'cardGeneric cardChild';

  
  const handleChangefilters = (tabId) => {
    setCurrentTab(tabId);
    setFilters(defaultFilters); };

  const actionOpenReports = async (regionData, staffData) => {
    const getAndSet = { get: reportFilters, set: setReportFilters };
    actionOpenDrawer('MasterDealSummarySheet', { filters: reportFilters, data: { regionData, staffData } }, dispatch, true, getAndSet, '400px') };
  

  useEffect(() => {
    const actionAsync = async () => {

      setLoading(true);

      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      await props.permissions.generalAccess();

      const regionsResult = await getRegions();
      const staffResult = await getUsers();
      const sopStaffResult = await getUsersByGroup("sop");
      const logisticsResult = getLogisticsStatus([0, 2, 3, 4, 5, 6, 25, 7, 30, 15, 10]);
      const warehousesResult = await Axios.get(`/entities/warehouses`).then(api => api.data.result);
      const regionsMapped = regionsResult.map(x => { return { label: x.name, value: x.id } });
      const staffMapped = staffResult
        .filter(x => String(x.azureGroups).includes('sop') || String(x.azureGroups).includes('sales'))
        .map(x => { return { label: x.fullName, value: x.activeDirectoryObjectGuid } });
      const sopStaffMapped = sopStaffResult.map(x => { return { label: x.displayName, value: x.azureId } });
      const logisticsMapped = logisticsResult.map(x => { return { label: x.status, value: x.value } });

      staffMapped.unshift({ label: "All Staff", value: -1 });
      sopStaffMapped.unshift({ label: "All SOP Staff", value: -1 });
      logisticsMapped.unshift({ label: "All Deal Statuses", value: -1 });
      
      setRegions(regionsMapped);
      setSopStaff(sopStaffMapped);
      setStaff(staffMapped);
      setLogistics(logisticsMapped);
      setWarehouse(warehousesResult);

      setLoading(false);

    }
    actionAsync();
  }, []);

  useEffect(() => {
    const actionAsync = async () => {
      if(!filters.queryString) return;
      const quickStats = await Axios.get(`/entities/workflow/deals/actionGetTopStats/${getTabContext(currentTab)}/?&$filter=${filters.queryString}`);
      setTopStats({ ...topStats, ...quickStats.data.result });
    }
    actionAsync();
  }, [filters]);


  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-item row width-100 mar-t5 mar-b10">
        {statCard(false, false, false, 'AVG Deal to invoice this month', (Number(topStats.avgMonth).toFixed(2)), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deals awaiting approval', (topStats.awaitingApproval), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deals awaiting approval over SLA', (topStats.awaitingApprovalOverSla), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Active Orders', (topStats.activeOrders), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'My Active Orders', (topStats.myActiveOrders), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'My Active Orders over SLA', (topStats.myActiveOrdersOverSLA), statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Orders Declined this month', (topStats.declinedThisMonth), statContainerClass, statChildClass)}
      </div>
      <div className="flex-container row wrap center width-100">
        <div className="flex-item start flex-grow-1 wrap mar-b5">
          <UILIB.Button value="Your Tasks" className="mar-r10 small" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/deptheads/", "_blank") }} />
          <UILIB.Button value="Master Deal Sheet" className="mar-r10 small" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/salesmasterdealsheet/Default.asp?", "_blank") }} />
          <UILIB.Button value="Part Management" className="primary mar-r10 small" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/salesmasterdealsheet/addeditprods/", "_blank") }} />
          <UILIB.Button value="Refurb Kit" className="secondary mar-r20 small" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/sales/refurbkit/default.asp?pageFilter=1&storeFilter=0", "_blank") }} />
        </div>
        <div className="flex-item end mar-b5">
          <UILIB.Button value="Reports" className="button mar-r10 small" disabled={loading} onClick={() => actionOpenReports(regions, staff)} />
          <UILIB.Button value="Power BI" className="button grey mar-r10 small" disabled={true} />
        </div>
      </div> 
      <div className="flex-container row width-100">
        <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => handleChangefilters(newId)} />
        {currentTab === 1 && <AwaitingApprovalTable
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          permissionsObject={permissionsObject} 
          localPermissions={localPermissions} 
          location={location} 
          permissions={permissions} 
          route={location.pathname} 
          currentTab={currentTab}
          regions={regions} 
          staff={staff} 
          logistics={logistics} />}
        {currentTab === 2 && <ApprovedTable
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          permissionsObject={permissionsObject} 
          localPermissions={localPermissions} 
          location={location} 
          permissions={permissions} 
          route={location.pathname} 
          currentTab={currentTab} 
          regions={regions} 
          staff={staff} 
          sopStaff={sopStaff} 
          logistics={logistics} 
          warehouse={warehouse} />}
        {currentTab === 3 && <InstallChecklistTable
          topStats={topStats}
          setTopStats={setTopStats}
          regions={regions}
          route={location.pathname}
          account={account}
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
          dealId={0} />}
      </div>
    </div >
  </Fragment>
})
export default SopHub