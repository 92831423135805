import { useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import Modules from './modules';
import UILIB from 'components';


export default function CustomerInventoryReport() {
    const [entityLookup, setEntityLookup] = useState({});
    const history = useHistory();

    return <Fragment>
        <div className="flex-container column mar-t10 mar-r5 mar-l5">
            <UILIB.Paper className='flex-item row width-100 mar-b20'>
                <div className='flex-item start width-100 mar-b20'><h3>{`Customer Inventory Report`}</h3></div>
                <div className='flex-item start'>
                    <UILIB.Button value="Back" className="grey" title="return to Sales Hub" onClick={() => history.push('/salesHub/accountmanagerview')} />
                </div>
            </UILIB.Paper>
            <UILIB.Paper className='flex-container row mar-b5'>
                <Modules.CustomerInventorySearch entityLookup={{ get: entityLookup, set: setEntityLookup }} />
            </UILIB.Paper>
            {Object.keys(entityLookup).length !== 0 && (
                <UILIB.Paper className='flex-container row mar-b5'>
                    <Modules.CustomerInventoryTable entityLookup={entityLookup} />
                </UILIB.Paper>
            )}
        </div>
    </Fragment>
}