import { useState, useRef, useEffect, Fragment } from 'react';
import { pleaseSelect, createDateInput, createTextInput, createSelectInputRetained, checkAndSet, newError } from 'classes/controls/genericControls';
import { formatDateTime } from 'classes/format';
import BlockSelectProductTable from './blockSelectProductTable';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment";

export default function InstallDraw({ row, isDisabled, onFinished, sync }) {

  const rowVersionMessageProduct = 'A newer version of this product record has already been saved. Please reload the data.';
  const rowVersionMessagePDI = 'A newer version of this PDI record has already been saved. Please reload the data.';
  const rowVersionMessageDeal = 'A newer version of this Deal record has already been saved. Please reload the data.';
  const rowVersionMessageInstall = 'A newer version of this install record has already been saved. Please reload the data.';
  const missingRecordMessageProduct = 'Unable to find product record in db. Please reload the data.';
  const missingRecordMessagePDI = 'Unable to find PDI record in db. Please reload the data.';
  const missingRecordMessageDeal = 'Unable to find Deal record in db. Please reload the data.';
  const missingRecordMessageInstall = 'Unable to find Install reading record in db. Please reload the data.';
  const unableToSaveWarningMessage = 'Unable to save current record, please contact support.';
  const single = 'Single Edit';
  const multi = 'Multi Edit';
  const notSelected = '-1';
  const yes = '1';
  const no = '0';
  const emptyEntity = {
      initialWarehouse: null,
      warehouse: null,
      dealInvoiceDate: null,
      invoiceNo: null,
      provInvoiceDate: null,
      moveDate: null,
      signedDealNoteStored: null,
      contractNumber: null,
      kpaxInstalled: null,
      kpaxNotInstalledReason: null,
      kpaxPukCode: null,
      rowVersionProduct: null,
      rowVersionPdi: null,
      provisionallyInvoicedMulti: null,
      signedDealNoteStoredMulti: null,
      contractNumberMulti: null }

  const validStatus = [0,-1];
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState(emptyEntity);
  const [errored, setErrored] = useState({ error: false, message: '' });
  const entityRaw = useRef(structuredClone(row))
  const [foundInstall, setFoundInstall] = useState(false);
  const [foundPdi, setFoundPdi] = useState(false);
  const [entityErrors, setEntityErrors] = useState(emptyEntity);
  const [saving, setSaving] = useState(false);
  const [singleOrMulti, setSingleOrMulti] = useState(single);
  const [selectedProducts, setSelectedProducts] = useState(false);
  const warehouses = useRef([pleaseSelect, ...row.warehouses]);
  const [products, setProducts] = useState([]);

  const yesNo = [
    { value: -1, label: pleaseSelect},
    { value: 0, label: 'No'},
    { value: 1, label: 'Yes'}];
  const kpaxNotInstalledReasons = [
    { value: -1, label: pleaseSelect},
    { value: 0, label: 'Customer refused'},
    { value: 1, label: 'No suitable PC to load'},
    { value: 2, label: 'Non-networked device'},
    { value: 3, label: 'Firewall restrictions'}];


  const loadSingle = async () => {
    setLoading(true);

    const product = entityRaw.current;
    const foundDeal = product.hasOwnProperty('deal');
    const foundPdi = product.hasOwnProperty('pdi') && product.pdi.length > 0;
    const foundInstall = (foundDeal && product.install.length);
    const invoiceNumber = (foundDeal) ? product.invoiceNo : 'No Product Data';
    const provInvoiceDate = (foundDeal) ? product.provInvoiceDate : 'No Product Data';
    const signedDealNoteStored = (foundPdi) ? product.pdi[0].signedDealNoteStored : 'No Pdi Data';
    const kpaxInstalled = (foundPdi) ? product.pdi[0].kpaxInstalled : 'No Pdi Data';
    const kpaxNotInstalledReason = (foundPdi) ? product.pdi[0].kpaxNotInstalledReason : 'No Pdi Data';
    const kpaxPukCode = (foundPdi) ? product.pdi[0].kpaxPukCode : 'No Pdi Data';
    const contractNumber = (foundPdi) ? product.pdi[0].contractNumber : 'No Pdi Data';
    const rowVersionPdi = (foundPdi) ? product.pdi[0].rowVersion : 'No Pdi Data';
    const rowVersionInstall = (foundInstall) ? product.install && product.install[0].rowVersion : 'No Install Data';
    const moveDate = product?.moveDate ? formatDateTime(product.moveDate, 'YYYY-MM-DD') : 'No Install Data';

    const theEntity = {
      initialWarehouse: product.warehouse,
      warehouse: product.warehouseId,
      dealInvoiceDate: (product.deal[0]?.invoiceDate) ? moment(product.deal[0]?.invoiceDate).format('DD/MM/YYYY') : 'N/A',
      invoiceNo: invoiceNumber,
      provInvoiceDate: (provInvoiceDate) ? moment(provInvoiceDate).format('YYYY-MM-DD') : null,
      moveDate: moveDate,
      signedDealNoteStored: (signedDealNoteStored === null || !signedDealNoteStored) ? '0' : '1',
      contractNumber: contractNumber,
      kpaxInstalled: (kpaxInstalled === null) ? '-1' : (kpaxInstalled === false) ? '0' : '1',
      kpaxNotInstalledReason: (kpaxNotInstalledReason === null) ? '-1' : kpaxNotInstalledReason,
      kpaxPukCode: kpaxPukCode,  
      rowVersionInstall: rowVersionInstall,
      rowVersionProduct: product.rowVersion,
      rowVersionPdi: rowVersionPdi };

    setFoundPdi(foundPdi);
    setFoundInstall(foundInstall);
    setEntity(theEntity);
  }    

  const loadMulti = async () => {
    const productResult = await Axios.get(`/entities/products?$filter=dealID eq ${row.dealID}&$expand=Attachments and singletons`);
    setProducts(productResult.data.result);
    setSelectedProducts(false);
  }

  useEffect(() => {
    const actionAsync = async () => {
      if(singleOrMulti === single) await loadSingle();
      if(singleOrMulti !== multi) await loadMulti();
      setLoading(false);
    }
    actionAsync();      
    // eslint-disable-next-line
  }, [singleOrMulti])

  const changeInput = (event) => {
    let newFormData = JSON.parse(JSON.stringify(entity));
    newFormData[event.target.name] = event.target.value;
    setEntity(newFormData);

    if (event.target.name in entityErrors) {
      let newFormErrors = { ...entityErrors };
      newFormErrors[event.target.name] = '';
      setEntityErrors(newFormErrors);
    }
  }

  const saveProduct = async () => {
    const productEntity = { rowVersion: row.rowVersion, warehouse: entity.warehouse, moveDate: entity.moveDate, invoiceDate: entity.provInvoiceDate };
    const updatedProduct = await Axios.put(`/entities/product/${row.dealID}/${entityRaw.current.prodID}`, { entity: { ...productEntity } });
    if (updatedProduct.status === 200) entityRaw.current = { ...entityRaw.current, ...updatedProduct.data.result };	
    if (updatedProduct.status !== 200) {
      if (updatedProduct.status === 409) setErrored({ error: true, message: rowVersionMessageProduct });
      if (updatedProduct.status === 404) setErrored({ error: true, message: missingRecordMessageProduct });
      return 1;
    }
    return 0;
  }

  const savePDI = async (pdiId) => {
    const pdiEntity = {  
      rowVersion: entityRaw.current.pdi[0].rowVersion,
      provisionallyInvoiced: entity.provInvoiceDate,
      contractNumber: entity.contractNumber,
      signedDealNoteStored: entity.signedDealNoteStored,
      kpaxInstalled: (entity.kpaxInstalled === '' || entity.kpaxInstalled === '-1') ? null : entity.kpaxInstalled,
      kpaxNotInstalledReason: (entity.kpaxNotInstalledReason === '' || entity.kpaxNotInstalledReason === '-1') ? null : entity.kpaxNotInstalledReason,
      kpaxPukCode: (entity.kpaxPukCode === '') ? null : entity.kpaxPukCode,
    };
    
    const updatedPdi = await Axios.put(`/entities/pdi/${row.dealID}/${pdiId}`, { entity: { ...pdiEntity } });
    if (updatedPdi.status === 200) entityRaw.current.pdi = [ { ...entityRaw.current.pdi[0], ...updatedPdi.data.result } ];
    if (updatedPdi.status !== 200) {
      if (updatedPdi.status === 409) setErrored({ error: true, message: rowVersionMessagePDI });
      if (updatedPdi.status === 404) setErrored({ error: true, message: missingRecordMessagePDI });
      return 1;
    }
    return 0;
  }

  const saveDeal = async (dealId) => {
    const products = await Axios.get(`/entities/pdis/?&$filter=dealID eq ${dealId}`);
    if (!products.data.result.some(x => !x.contractNumber)) {
      const pdiEntity = {  
        rowVersion: entityRaw.current.deal[0].rowVersion,
        contractStatus: 3
      };
      
      const updatedDeal = await Axios.put(`/entities/deal/${dealId}`, { entity: { ...pdiEntity } });
      if (updatedDeal.status === 200) entityRaw.current.pdi = [ { ...entityRaw.current.deal[0], ...updatedDeal.data.result } ];
      if (updatedDeal.status !== 200) {
        if (updatedDeal.status === 409) setErrored({ error: true, message: rowVersionMessageDeal });
        if (updatedDeal.status === 404) setErrored({ error: true, message: missingRecordMessageDeal });
        return 1;
      }
      return 0;
    } else {
      return 0;
    }
  }

  const saveInstall = async (installId) => {
    const installEntity = { rowVersion: entityRaw.current.install[0].rowVersion };
    const updatedInstall = await Axios.put(`/entities/install/${row.dealID}/${installId}`, { entity: { ...installEntity } });
    if (updatedInstall.status === 200) entityRaw.current.install = [{ ...entityRaw.current.install[0], ...updatedInstall.data.result  }];
    if (updatedInstall.status !== 200) {
      if (updatedInstall.status === 409) setErrored({ error: true, message: rowVersionMessageInstall });
      if (updatedInstall.status === 404) setErrored({ error: true, message: missingRecordMessageInstall});
      return 1;
    }
    return 0;
  }

  const cleanData = () => {
    if (entity.kpaxInstalled === notSelected) {
      entity.kpaxNotInstalledReason = '';
      entity.kpaxPukCode = ''
    }
    
    if (entity.kpaxInstalled === yes) { entity.kpaxNotInstalledReason = '' }
    if (entity.kpaxInstalled === no) { entity.kpaxPukCode = '' }
  }

  const evaluatedRulesPassed = (formErrorsTemp) => {
    let e = 0;
    if (entity.kpaxInstalled && entity.kpaxInstalled === yes) {
      e = e + checkAndSet(!entity.kpaxPukCode || entity.kpaxPukCode.length < 1, formErrorsTemp, 'kpaxPukCode', newError('kpaxPukCode', 'Please enter PUK Code'));
    }

    if (entity.kpaxInstalled && entity.kpaxInstalled === no) {
      e = e + checkAndSet(!entity.kpaxNotInstalledReason || entity.kpaxNotInstalledReason.length < 1 || entity.kpaxNotInstalledReason === notSelected, formErrorsTemp, 'kpaxNotInstalledReason', newError('kpaxNotInstalledReason', 'Please select from list'));
    }

    return e === 0;
  }

  const submitForm = async () => {
    try {

      setSaving(true);
      cleanData();

      const formErrors = [];
      if (!evaluatedRulesPassed(formErrors)) {
        setEntityErrors(formErrors);
        return false;
      }

      const pdiId = (entityRaw.current.pdi[0] && entityRaw.current.pdi[0].pdiID) ? entityRaw.current.pdi[0].pdiID : null;
      const installId = (entityRaw.current.install && entityRaw.current.install.length) ? entityRaw.current.install[0].id : null;
      const dealId = (entityRaw.current.deal[0] && entityRaw.current.deal[0].dealID) ? entityRaw.current.deal[0].dealID : null;

      const productResult = await saveProduct();
      const pdiResult = pdiId ? await savePDI(pdiId) : -1;
      const installResult = installId ? await saveInstall(installId) : -1;
      const dealResult = dealId ? await saveDeal(dealId) : -1;

      if(validStatus.includes(productResult) && validStatus.includes(pdiResult) && validStatus.includes(installResult) && validStatus.includes(dealResult)) {
        setSaving(false);
        sync.set(!sync.get);
        return onFinished();
      }

      setSaving(false);
      sync.set(!sync.get);
      onFinished(entityRaw.current, false);
      return true;
    }
    catch (err) {
        console.log(err);
        setSaving(false);
        setErrored({ error: true, message: unableToSaveWarningMessage });
    }
  }

  const submitFormMulti = async () => {
    try {      
      let pdiEntity = { };
      let haveChanges = false;
      if (entity.provisionallyInvoicedMulti !== undefined) { 
        pdiEntity = { ...pdiEntity, provisionallyInvoiced: entity.provisionallyInvoicedMulti}
        haveChanges = true;
      }
      if (entity.contractNumberMulti !== undefined) { 
        pdiEntity = { ...pdiEntity, contractNumber: entity.contractNumberMulti}
        haveChanges = true;
      }
      if (entity.signedDealNoteStoredMulti !== undefined) { 
        pdiEntity = { ...pdiEntity, signedDealNoteStored: entity.signedDealNoteStoredMulti}
        haveChanges = true;
      }

      if (haveChanges && selectedProducts) {
        const productsForVersion = await Axios.get(`/entities/pdis?$filter=dealID eq ${row.dealID}`).then(api => api.data.result);
        products.filter(f => f.installSelected).forEach(async x => {
          const pdiRow = productsForVersion.find(y => x.prodID === y.productId);
          pdiEntity = { ...pdiEntity, rowVersion: pdiRow.rowVersion }      
          await Axios.put(`/entities/pdi/${row.dealID}/${pdiRow.pdiID}`, { entity: { ...pdiEntity } });
        })
        sync.set(!sync.get);
        onFinished(entityRaw.current, false);
      }
    }
    catch (err) {
        console.log(err);
        setSaving(false);
        setErrored({ error: true, message: unableToSaveWarningMessage });
    }
  }

  const changeEdit = async (newEdit) => {
    (newEdit === 'single') ? setSingleOrMulti(single) : setSingleOrMulti(multi);
  }

  return <Fragment>
    <h2 className='mar-b15'>Update Checklist</h2>
    {errored.error && <div className="flex-container end">
      <div className="errored message">{errored.message}</div>
    </div>}
    {loading && <UILIB.Loading type={3} />}
    {!loading && singleOrMulti === single && <div>
      <UILIB.Button disabled={isDisabled} className='mar-b15' value={'Go to ' + multi} onClick={() => changeEdit('multi')} />
      {createSelectInputRetained('Warehouse', 'warehouse', warehouses.current, isDisabled, entity, entityErrors, changeInput)}
      <h3 className='mar-t15'>Invoicing</h3>
      {createDateInput('Provisional Invoice Date', 'provInvoiceDate', (!isDisabled) || (!isDisabled && !foundPdi), entity, entityErrors, changeInput)}
      {createTextInput('Invoice #', 'invoiceNo', false, 40, entity, entityErrors, changeInput)}
      {createDateInput('Deal Invoice Date', 'dealInvoiceDate', false, entity, entityErrors, changeInput)}
      <h3 className='mar-t15'>Install</h3>
      {createDateInput('Install Date', 'moveDate', true, entity, entityErrors, changeInput)}
      {createSelectInputRetained('Signed Delivery Note Saved?', 'signedDealNoteStored', yesNo, isDisabled || !foundPdi, entity, entityErrors, changeInput)}
      <h3 className='mar-t15'>Contract</h3>
      {createTextInput('Contract #', 'contractNumber', (!isDisabled) || (!isDisabled && !foundPdi), 40, entity, entityErrors, changeInput)}
      <h3 className='mar-t15'>KPAX</h3>
      {createSelectInputRetained('KPAX Installed', 'kpaxInstalled', yesNo, isDisabled || !foundPdi, entity, entityErrors, changeInput)}
      {entity.kpaxInstalled === no &&
        createSelectInputRetained('Reason Not Installed', 'kpaxNotInstalledReason', kpaxNotInstalledReasons, isDisabled || !foundPdi, entity, entityErrors, changeInput)}
      {entity.kpaxInstalled === yes &&
        createTextInput('PUK Code', 'kpaxPukCode', (!isDisabled) || (!isDisabled && !foundPdi), 40, entity, entityErrors, changeInput)}
      {<UILIB.Button disabled={isDisabled || saving} loading={saving} className='mar-t10' value='Save' onClick={submitForm} />}
    </div>}
    {!loading && singleOrMulti === multi && <div>
      <UILIB.Button className='mar-b15' value={'Go to ' + single} onClick={() => changeEdit('single')} />
      {createDateInput('Provisional Invoice', 'provisionallyInvoicedMulti', (!isDisabled) || (!isDisabled && !foundPdi), entity, entityErrors, changeInput)}
      {createSelectInputRetained('Signed Delivery Note Saved?', 'signedDealNoteStoredMulti', yesNo, isDisabled || !foundPdi, entity, entityErrors, changeInput)}
      {createTextInput('Contract #', 'contractNumberMulti', (!isDisabled) || (!isDisabled && !foundPdi), 40, entity, entityErrors, changeInput)}
      <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: 0, margin: 0 }}>
      <h4 style={{ padding: 0, marginBottom: 5 }}>Please select required Products</h4>
    </div>
    <div className='col-xs-12 col-sm-12 col-md-12'>
      {Boolean(products.length) && 
        <BlockSelectProductTable 
          data={products} 
          setData={setProducts} 
          selectedProducts={selectedProducts} 
          setSelectedProducts={setSelectedProducts}
          loading={loading} />}
      </div>
      {
        selectedProducts
        && (
            (entity.provisionallyInvoicedMulti !== undefined && entity.provisionallyInvoicedMulti !== '')
            || (entity.contractNumberMulti !== undefined && entity.contractNumberMulti !== '')
            || (entity.signedDealNoteStoredMulti !== undefined && entity.signedDealNoteStoredMulti !== '-1')
          )
        && <UILIB.Button disabled={saving} loading={saving} className='mar-t10' value='Save' onClick={submitFormMulti} />}
    </div>}
  </Fragment>
}
