import { useState, useEffect, Fragment } from 'react';
import { setOrder, calcHeaderColour, spawnHeaderType } from '../functions';

export default function ColumnHeader({ header, settings, render, setRender, moduleRender, setModuleRender, checked, setChecked, selectAll, tableData, selectQuery }) 
{
  const [renderLocal, setRenderLocal] = useState(false);

  const changeOption = (col, header, settingsLocal, filtering) => {
    if(!filtering) return false;
    const click = (!isNaN(settingsLocal.current.paging.orderClick)) 
      ? (settingsLocal.current.paging.orderClick === 2) ? 0 : (settingsLocal.current.paging.orderClick + 1) 
      : 0;
    const orderBy = (click === 0) ? '' : settingsLocal.current.paging.orderBy;
    const orderDir = (click === 0) ? '' : settingsLocal.current.paging.orderDir;
    const settingsBase = { ...settingsLocal.current, paging: { ...settingsLocal.current.paging, orderBy: orderBy, orderDir: orderDir, orderClick: click } };
    setOrder(col, header, settingsBase, settings);
    setRenderLocal(!renderLocal);
  }

  const changeHeaderDirection = (direction, orderClick, style, sortable, col, settingsLocal) => {
    if (settingsLocal.current.sortable && col.type) style.cursor = 'pointer';
    const active = (settingsLocal.current.paging.orderBy === col.value && Number(settingsLocal.current.paging.orderClick) !== 0);
    const asc = (direction === 'ASC' && orderClick !== 0 && (sortable && col.type) && active) ? ' hide ' : '';
    const desc = (direction === 'DESC' && orderClick !== 0 && (sortable && col.type) && active) ? ' hide ' : '';
    return  <div className="flex-container center column around nowrap pad-r2">
              <div className="flex-item sortLabel"><div className={`up-arrow ${asc}`}></div></div>
              <div className="flex-item sortLabel"><div className={`down-arrow ${desc}`}></div></div>
            </div>
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      setRender(!render);
    }, 100);
    return () => clearTimeout(timer);
  }, [renderLocal])

  return header.map((col, index) => {
    const minWidth = (col?.minWidth && !col?.width) ? { minWidth: col?.minWidth } : {};
    const maxWidth = (col?.maxWidth && !col?.width) ? { maxWidth: col?.maxWidth } : {};
    const width = (isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth)) ? { width: 'auto' } : {};
    const widthFinal = ((isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth) && isNaN(width?.width))) ? { width: 'auto' } : {};
    const align = (col?.align) ? col?.align : '';
    const style = { textAlign: align, ...minWidth, ...maxWidth, ...width, ...widthFinal };
    const filtering = (col?.filtering);
    col.style = style;
    return <th key={"th_" + index} className={`${calcHeaderColour(settings.current.paging, col, 2)} mainTable headerBorders`} style={style} onClick={() => changeOption(col, header, settings, filtering)}>
      <Fragment>
        <div className="flex-container center row nowrap width-100 height-100 row-hover">
          {spawnHeaderType(checked, setChecked, selectAll, col, tableData, moduleRender, setModuleRender, selectQuery)}
          {filtering && <div className="flex-item end">{changeHeaderDirection(settings.current.paging.orderDir, settings.current.paging.orderClick, style, settings.current.sortable, col, settings)}</div>}
        </div>
      </Fragment>
    </th>
  })
}
