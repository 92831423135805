import { useState, Fragment } from 'react';
import Modules from '../Modules';
import UILIB from 'components';


export default function SopDrawer ({ deal, sync, onFinished }) {

  const [selected, setSelected] = useState(1);

  const actionSelectView = (ev) => {
    setSelected(Number(ev.target.name));
  };  

  return <Fragment>         
    <div className="flex-container row start width-100">
      <div className='flex-item flex-grow-1 start wrap'>
        <UILIB.Button className={'small ' + (Number(selected) === 1 ? 'primary' : 'secondary')} name={1} value='SOP Settings' onClick={(ev) => actionSelectView(ev)} />
        <UILIB.Button className={'mar-l10 small ' + (Number(selected) === 2 ? 'primary' : 'secondary')} name={2} value='CRM Notes' onClick={(ev) => actionSelectView(ev)} />
      </div>
      {selected === 1 && <Modules.EditSop deal={deal} sync={sync} onFinished={onFinished} />}
      {selected === 2 && <Modules.NoteCRMSync deal={deal} sync={sync} onFinished={onFinished} />}
    </div>
  </Fragment>   
}
