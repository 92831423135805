export async function getFormErrors(formErrors, tabErrors, callback) {

  let totalCount = 0;
  let errorCount = 0;

  Object.values(formErrors).forEach(err => { if(err.disabled === false) totalCount++ });
  Object.values(formErrors).forEach(err => { return err.error = false });

  Object.keys(formErrors).forEach(key => {
    
    const found = formErrors[key];

    if(Boolean(found) && !found.disabled && found.condition) {
      if(tabErrors && found.subNav !== null && !tabErrors.includes(found.subNav)) tabErrors.push(found.subNav);
      found.error = true;
      errorCount++;
    }
  })

  if(callback) await callback();

  return {
  	formErrors: formErrors,
  	totalCount: totalCount, 
  	errorCount: errorCount
  };
}

export async function checkFormErrors(formErrors, setFormErrors, tabErrors, setTabErrors, formData, callback) {

  let totalCount = 0;
  let errorCount = 0;

  Object.values(formErrors).forEach(err => { if(err.disabled === false) totalCount++ });
  Object.values(formErrors).forEach(err => { return err.error = false });

  Object.entries(formData).forEach(([key, value]) => {
    
    const found = formErrors[key];

    if(Boolean(found) && !found.disabled) {
      if((found.hasOwnProperty("condition") && found.condition)) {
        if(tabErrors && found.subNav !== null && !tabErrors.includes(found.subNav)) tabErrors.push(found.subNav);
        found.error = true;
        errorCount++;
        return;
      }
      if(!found.hasOwnProperty("condition") && (value === null || value === 'null' || value === '')) {
        if(tabErrors && found.subNav !== null && !tabErrors.includes(found.subNav)) tabErrors.push(found.subNav);
        found.error = true;
        errorCount++;
        return;
      }
      if(!found.hasOwnProperty("condition") && Number(value) === -1) {
        if(tabErrors && found.subNav !== null && !tabErrors.includes(found.subNav)) tabErrors.push(found.subNav);
        found.error = true;
        errorCount++;
        return;
      }
    }
  })

  if(setFormErrors) setFormErrors(structuredClone(formErrors));
  if(setTabErrors) setTabErrors(structuredClone(tabErrors));

  if(callback) await callback();

  return {
  	formErrors: formErrors,
  	formData: formData, 
  	totalCount: totalCount, 
  	errorCount: errorCount
  };
}

export async function checkProductErrors(productErrors, setProductErrors, tabErrors, setTabErrors, productData, callback) {

  let totalCount = 0;
  let errorCount = 0;

  productErrors.forEach(err => { if(err.disabled === false) totalCount++ });
  productErrors.forEach(err => { return err.error = false });

  productData.forEach(product => {

    const found = productErrors.filter(f => f.rowId === product.prodID && f.disabled === false);

    if(Boolean(found) && !found.disabled) {

      found.forEach(f => {
        
        if(f.hasOwnProperty("condition") && f.condition) {  
          if(tabErrors && f.subNav !== null && !tabErrors.includes(f.subNav)) tabErrors.push(f.subNav);
          f.error = true;
          errorCount++;
          return;
        }
        if(!f.hasOwnProperty("condition") && (f.value === null || f.value === 'null' || f.value === '')) {
          if(tabErrors && f.subNav !== null && !tabErrors.includes(f.subNav)) tabErrors.push(f.subNav);
          f.error = true;
          errorCount++;
          return;
        }
        if(!f.hasOwnProperty("condition") && Number(f.value) === -1) {
          if(tabErrors && f.subNav !== null && !tabErrors.includes(f.subNav)) tabErrors.push(f.subNav);
          f.error = true;
          errorCount++;
          return;
        }
      })
    }
  })

  if(setProductErrors) setProductErrors(structuredClone(productErrors));
  if(setTabErrors) setTabErrors(structuredClone(tabErrors));

  if(callback) await callback();

  return {
  	formErrors: productErrors,
  	formData: productData, 
  	totalCount: totalCount, 
  	errorCount: errorCount
  };
}

export async function checkAdditionalInfoErrors(additionalInfoErrors, setAdditionalErrors, tabErrors, setTabErrors, rowData, callback) {

  let totalCount = 0;
  let errorCount = 0;

  Object.values(additionalInfoErrors).filter(f => !f.disabled).forEach((value) => value.fields.forEach(() => totalCount++));
  Object.values(additionalInfoErrors).forEach(err => { return err.error = false });

  Object.values(additionalInfoErrors).filter(f => !f.disabled).forEach((value, i) => {

    value.fields.forEach(f => {

      const found = Object.entries(rowData.keyContact[i]).find(([key, value]) => key === f);

      if(found !== null) {
        if((found[1] === null || found[1] === 'null' || found[1].match(/^ *$/) !== null) || Number(found[1]) === -1) {
          if(tabErrors && value.subNav !== null && !tabErrors.includes(value.subNav)) tabErrors.push(value.subNav);
          value.error = true;
          errorCount++;
          return;
        }
      }
    })
  })

  if(setAdditionalErrors) setAdditionalErrors(structuredClone(additionalInfoErrors));
  if(setTabErrors) setTabErrors(structuredClone(tabErrors));

  if(callback) await callback();

  return {
  	formErrors: additionalInfoErrors,
  	formData: rowData, 
  	totalCount: totalCount, 
  	errorCount: errorCount
  };
}

export async function checkDocumentErrors(documentErrors, setDocumentErrors, tabErrors, setTabErrors, rowData, callback) {

  let totalCount = 0;
  let errorCount = 0;
  let error = false;

  documentErrors.documents.fields.forEach(fields => { if(fields.endsWith("*")) totalCount++ } );

  documentErrors.documents.fields.filter(f => f.endsWith("*")).forEach(row => {

    const found = rowData.find(found => found.Class.toLowerCase().replace(/\s/g, '').replace('*', '') === row.toLowerCase().replace(/\s/g, '').replace('*', ''));

    if(!Boolean(found)) {
      if(tabErrors && documentErrors.documents.subNav !== null && !tabErrors.includes(documentErrors.documents.subNav)) tabErrors.push(documentErrors.documents.subNav);
      error = true;
      errorCount++;
      return;
    }
  })

  documentErrors.documents = { ...documentErrors.documents, error: error };
  if(setDocumentErrors) setDocumentErrors(structuredClone(documentErrors));
  if(setTabErrors) setTabErrors(structuredClone(tabErrors));

  if(callback) await callback();

  return {
  	formErrors: documentErrors,
  	formData: rowData, 
  	totalCount: totalCount, 
  	errorCount: errorCount
  };
}

export async function callback(formErrors, setFormErrors, ms) {
  setTimeout(() => {
    const formErrorsNew = {...formErrors};
    Object.values(formErrorsNew).forEach(e => { e.error = false; });
    setFormErrors(formErrorsNew);
  }, ms)
}

export function setConditionMessage(condition, message) {
  const index = condition.findIndex(x => x === true);
  return { condition: index > -1 ? condition[index] : false, messageText: index > -1 ? message[index] : '' }
}

export function getEmptyError(formErrors, data, key, condition, messageText, option, subNav, disabled, error) { 
  
  const dataLocal = (data) ? data : '';
  const messageLocal = (messageText) ? messageText : 'Please enter message text';
  const optionLocal = (option && option === 1) ? option : 0;
  const subNavLocal =  (subNav && subNav === 1) ? subNav : 0;
  const disabledLocal = (disabled) ? disabled : false;
  const conditionLocal = (condition) ? condition : false;
  const errorLocal = (error) ? error : false;

  const errorObject = { [key]: { subNav: subNavLocal, data: dataLocal, option: optionLocal, condition: conditionLocal, messageText: messageLocal, error: errorLocal, disabled: disabledLocal } };
  formErrors.current = { ...formErrors.current, ...errorObject };
  return errorObject[key];
}