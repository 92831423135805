import { useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import Axios from "classes/axios";
import UILIB from "components";
import { formatDateTime } from "classes/format";
import { getYesNo, actionOpenNotesDrawer, tableContainer, rowSelected, selectAllRows, checkRow, getSelectedRowIds, getSelectedNoteIds } from '../functions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MIFExceptionsTable({ context }) {

  const dispatch = useDispatch();
  const entityData = useRef({});
  const defaultDialogue = { exceptionData: [], noteIds: [], count: 0, show: false };
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDialogue);
  const [showRestoreDialogue, setShowRestoreDialogue] = useState(defaultDialogue);
  const [deleting, setDeleting] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [filter, setFilter] = useState({ button: 'Active', filter: 'isDeleted eq 0' });
  const notFoundError = 'The server was unable to find a requested endpoint, please reload or contact support';
  const invalidConfigError = 'The request could not be processed due to invalid input or configuration, please reload or contact support';
  const deleteExceptionError = 'There was an exception while deleting this record, please reload or contact support';
  const exceptions = [400,404,422];

  const selectAllCheckboxColumn = 
    [{
      label: (
        <UILIB.Checkbox
        onChange={(event) => selectAllRows(event, entityData, setSelectedRows)}
        checked={
          entityData.current.result &&
          entityData.current.result.length > 0 &&
          entityData.current.result.every(row => selectedRows[row.id]?.isChecked)
        }
      />
      ),
      value: "rowSelected",
      align: "left",
      type: 'number'
    }];

  const auditColumns = () => {
    if(filter.button === "Active") {
      return [
        { label: "Modified Date", value: "modifiedDate", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
        { label: "Modified By", value: "modifiedBy", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
      ]
    }
    else {
      return [
        { label: "Deleted Date", value: "dateDeleted", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
        { label: "Deleted By", value: "deletedBy", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
      ]
    }
  }

  const headerData = [
    ...selectAllCheckboxColumn,
    { label: "Customer Name", value: "customerName", type: "string", filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Serial Number", value: "SerialNumber", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Description", value: "description", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Exception Reason", value: "reason", type: "string", filtering: true, minWidth: 300, maxWidth: 300 },
    { label: "Creation Date", value: "createdDate", type: "date", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Created By", value: "createdBy", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    ...auditColumns(),
    { label: "Notes", value: "note", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
  ];

  let orderBy = '';

  if(context.value === 1) {
    orderBy = 'impacted'
  }
  else if(context.value === 2) {
    orderBy = 'neverCommunicated'
  }

  const pageSelectedNumber = 50;
  const tablePageDefaults = { deleteEnabled: false, paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: orderBy, orderDir: 'DESC' } };

  const [localRender, setLocalRender] = useState(false);
  const [remoteRender, setRemoteRender] = useState(false);

  const handleDeleteExceptionClicked = async () => {
    const selectedRowIds = Object.entries(selectedRows)
      .filter(([id, { isChecked, isException }]) => isChecked && isException)
      .map(([id]) => Number(id));

    const selectedEntities = entityData.current.result
      .filter(row => selectedRowIds.includes(row.id))
      .map(({ id, customerId, ...rest }) => ({
        id: id,
        CustomerId: customerId,
        ...rest,
      }));

    const selectedNoteIds = getSelectedNoteIds(selectedEntities);
    setShowDeleteDialogue({ count: selectedRowIds.length, show: true, exceptionData: selectedRowIds, noteIds: selectedNoteIds });
  };

  const handleRestoreExceptionClicked = async () => {
    const selectedRowIds = getSelectedRowIds(selectedRows);
    setShowRestoreDialogue({ count: selectedRowIds.length, show: true, exceptionData: selectedRowIds });
  };

  const handleButtonsChanged = (buttonName, set) => {
    set(prevFilter => {
      const newTableFilter = { ...prevFilter };
      newTableFilter.button = buttonName;
      newTableFilter.filter = buttonName === 'Active' 
        ? 'isDeleted eq 0' 
        : 'isDeleted eq 1';
  
      return newTableFilter;
    });
  };

  const handleDelete = async (selectedRowIds, noteIds) => {
    setDeleting(true);

    try {
      const [exceptionDeleteResponse, mifNoteDeleteResponse, voNoteDeleteResponse] = await Promise.all([
        Axios.delete(`/entities/mif_exceptions/mifExceptions/softDelete`, { data: { exceptionIds: selectedRowIds } }),
        Axios.delete(`/entities/mifNotes/softDelete`, { data: { exceptionIds: selectedRowIds } }),
        Axios.delete(`/entities/mifNotes`, { data: { noteIds: noteIds } }),
      ]);

      const responseStatuses = [
        exceptionDeleteResponse.status,
        mifNoteDeleteResponse.status,
        voNoteDeleteResponse.status,
      ];

      if(responseStatuses.some(status => exceptions.includes(status))) {
        if (responseStatuses.some(status => status === 400)) {
          toast.error({ error: true, message: invalidConfigError });
        }
        if (responseStatuses.some(status => status === 404)) {
          toast.error({ error: true, message: notFoundError });
        }
      }
      else {
        const selectedIds = selectedRowIds.map(item => Number(item.id));

        entityData.current.result.forEach(x => {
          if (selectedIds.includes(Number(x.id))) {
            x.notes = [];
          }
        });

        entityData.current.result = entityData.current.result.filter(x => !showDeleteDialogue.exceptionData.includes(Number(x.id)));

        setShowDeleteDialogue(defaultDialogue);
        setLocalRender(!localRender);
        setSelectedRows({});
      }
    } catch (error) {
      console.error('Error deleting', error);
      toast.error(deleteExceptionError);
    }


    setDeleting(false);
  }

  const handleRestore = async (selectedRowIds) => {
    setRestoring(true);

    try {
      const [exceptionRestoreResponse, noteRestoreResponse] = await Promise.all([
        Axios.put(`/entities/mif_exceptions/mifExceptions/restore`, { exceptionData: selectedRowIds }),
        Axios.put(`/entities/mifNotes/restore`, { exceptionData: selectedRowIds }),
      ]);
  
      const responseStatuses = [
        exceptionRestoreResponse.status,
        noteRestoreResponse.status,
      ];


      if(responseStatuses.some(status => exceptions.includes(status))) {
        if (responseStatuses.some(status => status === 400)) {
          toast.error({ error: true, message: invalidConfigError });
        }
        if (responseStatuses.some(status => status === 404)) {
          toast.error({ error: true, message: notFoundError });
        }
      }
      else {
        const selectedIds = selectedRowIds.map(m => Number(m.id));
        entityData.current.result = entityData.current.result.filter(x => !selectedIds.includes(Number(x.id)));

        setShowRestoreDialogue(defaultDialogue);
        setLocalRender(!localRender);
        setSelectedRows({});
      }
    } catch (error) {
      console.error('Error restoring mif exception', error);
      toast.error(deleteExceptionError);
    }

    setRestoring(false);
  }

  async function constructTable(mifData) {

    let tableData = [];

    tableData = mifData.map(row => {
      const hasVoNotes = Boolean(row.voNotes && row.voNotes.length);
      const hasMifNotes = Boolean((row.notes && row.notes.length && row.notes.every(note => note.isDeleted)));
      const isSoftDelete = filter.button === 'Deleted';
      const b_hasNotes = (hasVoNotes && !isSoftDelete) || (hasMifNotes && isSoftDelete);

      const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender }, true);
      let notesColour;
      let notesValue;

      if (!b_hasNotes) {
        notesColour = 'colour lightGrey';
        notesValue = 'No notes available';
      }
      else if(isSoftDelete) {
        notesColour = 'colour background-6 green';
        notesValue = 'View Notes - MIF';
      }
      else {
        notesColour = 'colour background-6 red';
        notesValue = 'View Notes - VO';
      }

      const isChecked = selectedRows[row.id]?.isChecked || false;

      return {
        rowSelected: { value: <UILIB.Checkbox name="rowSelected" checked={!!isChecked} onChange={(event) => rowSelected(event, row, setSelectedRows, 'exceptionsList')} />, raw: row.id },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        description: { value: row.description, raw: row.description },
        customerName: { value: row.customerName, raw: row.customerName },
        reason: { value: row.reason, raw: row.reason },
        createdDate: { value: formatDateTime(row.createdDateConvert, "DD/MM/YYYY"), raw: row.createdDate },
        createdBy: { value: row.createdBy, raw: row.createdBy },
        modifiedDate: { value: formatDateTime(row.modifiedDateConvert, "DD/MM/YYYY"), raw: row.modifiedDate },
        modifiedBy: { value: row.modifiedBy ? row.modifiedBy : 'N/A', raw: row.modifiedBy },
        dateDeleted: { value: formatDateTime(row.dateDeletedConvert, "DD/MM/YYYY"), raw: row.dateDeleted },
        deletedBy: { value: row.deletedBy ? row.deletedBy : 'N/A', raw: row.deletedBy },
        note: { value: tableContainer(fn_notes, 'icon-pencil', `${notesValue}`, notesColour, true), raw: notesValue },
      };
    });

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {
    try {
      const expandArray = (source === 'primary' && filter.button === 'Deleted')
        ? ['notes']
        : (source === 'primary' && filter.button === 'Active')
        ? ['voNotes']
        : [];
      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const baseFilter = filter.filter;

      entityData.current = await Axios.get(`/entities/mif_exceptions/getSummary/?&$filter=${baseFilter} ${queryLocal}&$notes=${baseFilter}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if(!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }
    catch(e) {
      console.log(`Exception: ${e}`);
    }
  }

  return (
    
    <Fragment>
        {showDeleteDialogue.show && <UILIB.MessageBox 
          header={'Delete Records'} 
          loading={deleting}
          text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Records' : 'Record'}`} 
          onConfirm={async () => await handleDelete(showDeleteDialogue.exceptionData, showDeleteDialogue.noteIds)} 
          onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} />}
        {showRestoreDialogue.show && <UILIB.MessageBox 
          header={'Restore Records'} 
          loading={restoring}
          text={`Click OK to RESTORE the selected ${(showRestoreDialogue.count) > 1 ? 'Records' : 'Record'}`} 
          onConfirm={async () => await handleRestore(showRestoreDialogue.exceptionData)} 
          onCancel={() => setShowRestoreDialogue({ ...showRestoreDialogue, show: false })} />}
      <UILIB.Paper className="width-100">
      <div class="flex-container row mar-b10">
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
        <div className='flex-item end'>
        {filter.button === 'Active' ? (
          <UILIB.Button 
            className="button height-100 mar-l5 small red" 
            value='Delete exception' 
            onClick={async () => await handleDeleteExceptionClicked()}
            disabled={
              !Object.values(selectedRows).some(row => row.isChecked) ||
              !Object.keys(selectedRows).some(id => selectedRows[id].isChecked)
            }
          />
        ) : filter.button === 'Deleted' ? (
          <UILIB.Button 
            className="button height-100 mar-l5 small green" 
            value='Restore exception' 
            onClick={async () => await handleRestoreExceptionClicked()}
            disabled={!Object.values(selectedRows).some(value => value) ||
            !Object.keys(selectedRows).some(id => selectedRows[id] && checkRow(id, entityData, selectedRows, filter.button === 'Active'))}
          />
        ) : null}
          </div>
        </div>
        <UILIB.TableNew
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
          localRender={[selectedRows, localRender]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender, filter]}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
  );
}
