import { toast } from 'react-toastify';
import { regexValidation } from '../../classes/dataValidation';
import { setPagePreferences } from 'store/actions';
import { store } from 'store/store';
import Modules from './Modules';


export function setOrder(col, header, data, settings) {
  if (data.paging.orderBy === '') {
    settings.current = setCacheData({ ...data, paging: { ...data.paging, orderDir: 'DESC', orderBy: header[0].value } });
  } else {
    settings.current = setCacheData({ ...data, paging: { ...data.paging, orderDir: (data.paging.orderDir === 'ASC') ? 'DESC' : 'ASC', orderBy: col.value } });
  }
}

export function spawnContainerType(type, behaviorType, data, checked, setChecked, selectQuery) {
  if(type === 'delete') return <Modules.SelectContainer data={data} behaviorType={behaviorType} checked={checked} setChecked={setChecked} />
  if(type === 'select') return <Modules.SelectContainer data={data} behaviorType={behaviorType} checked={checked} setChecked={setChecked} selectQuery={selectQuery} />
  return data.value;
};

export function spawnHeaderType(checked, setChecked, selectAll, col, tableData, moduleRender, setModuleRender, selectQuery) {
  if(col.type === 'select') return <Modules.SelectHeader checked={checked} setChecked={setChecked} selectAll={selectAll} tableData={tableData} col={col} render={moduleRender} setRender={setModuleRender} selectQuery={selectQuery} />
  return <div className="flex-item start flex-grow-1 sortLabel width-100 text-10">{col.label}</div>;
};

export function appendSelected(data, header, checked, setChecked) {
  const found = header.find(x => x?.type === 'select');
  if(found) {
    data.forEach(x => { checked = { [x[found.value].value]: false, ...checked } });
    setChecked(checked); }
};

export function getTablefilters(type, filters, name) {

  const isEmpty = (filters && filters.some(x => Number(x.filter) === 9 && Number(x.value) === 0 && x.name === name));
  const isNull = (filters && filters.some(x => Number(x.filter) === 10 && Number(x.value) === 0 && x.name === name));

  const filterArray = [
    { value: 1, label: "None", expression: '' },
    { value: 2, label: "Contains", expression: 'like' },
    { value: 3, label: "Starts With", expression: 'startsLike' },
    { value: 4, label: "Ends With", expression: 'endsLike' },
    { value: 5, label: "Equals", expression: 'eq' },
    { value: 6, label: "Excludes", expression: 'notLike' },
    { value: 7, label: "Greater Than", expression: 'gt' },
    { value: 8, label: "Greater Than Or Equal To", expression: 'gte' },
    { value: 9, label: "Is Empty", expression: (isEmpty) ? 'eq' : 'ne' },
    { value: 10, label: "Is Null", expression: (isNull) ? 'is' : 'isn' },
    { value: 11, label: "Less Than", expression: 'lt' },
    { value: 12, label: "Less Than Or Equal To", expression: 'lte' },
    { value: 13, label: "Not Equal To", expression: 'ne' }]
  if(type === 'string') return filterArray.filter(x => ![7,8,11,12].includes(x.value));
  if(type === 'date' || type === 'datetime') return filterArray.filter((x) => ![2,3,4,6].includes(x.value));
  return filterArray;
};

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "True" },{ value: 1, label: "False" }]
}

export function getSelector(type, value) {
  const types = [9,10];
  if(!types.includes(type) && regexValidation('specialCharacters', value)) return `'${value}'`;
  if(type === 9) return "";
  if(type === 10) return 'NULL';
  return value;
}

export function getCancelToken(source, cancelToken) {
  if (source.current) source.current.cancel('cancelled');
  const newSource = cancelToken.current.source();
  source.current = newSource;
  return newSource;
}

export async function filterEngine(query, settings, renderOverride, cancelToken) {
  const pagingLimit = settings.current.paging.limit;
  const queryAppend = settings.current.filters.filter(f => f.filter !== 1 && f.value.length !== 0).map(x => { 
    return encodeURIComponent(`${x.name} ${getTablefilters(x.type, settings.current.filters, x.name).find(f => f.value === x.filter).expression} ${getSelector(x.filter, x.value)}`) }).join(' and ');
  if(!renderOverride && !settings.filters.filter(f => f.filter !== 1).length) return;
  const queryResult = await query(`and ${queryAppend}`, pagingLimit, settings.current.paging.offset, settings.current.paging.orderBy, settings.current.paging.orderDir, cancelToken, 'primary');
  if(queryResult) {
    settings.current = { ...settings.current, paging: { ...settings.current.paging, totalRows: queryResult.nonePaged }};
    return queryResult;
  } else {
    settings.current = { ...settings.current, paging: { ...settings.current.paging, totalRows: 0, nonePaged: 0 }};
    return { tableData: [], raw: [], nonePaged: 0, totalRows: 0 };
  }
}

export async function exportEngine(query, settings, cancelToken) {
  const returnObject = { tableData: [] };
  for(var i = 0; i < Number(settings.current.paging.totalRows); i = (i + 2000))
  {
    const queryAppend = settings.current.filters.filter(f => f.filter !== 1 && f.value.length !== 0).map(x => { 
      return encodeURIComponent(`${x.name} ${getTablefilters(x.type, settings.current.filters, x.name).find(f => f.value === x.filter).expression} ${getSelector(x.filter, x.value)}`) }).join(' and ');
      const queryResult = await query(`and ${queryAppend}`, 2000, i, settings.current.paging.orderBy, settings.current.paging.orderDir, cancelToken, 'export');
      returnObject.tableData.push(...queryResult.tableData);
      toast.info(`Exporting (${Math.floor((returnObject.tableData.length / Number(settings.current.paging.totalRows)) * 100)}%)`);
  }
  return returnObject;
}

export function calcLocalRender(settings, tableDataLocal, queryResult, tableData, render, setRender) {
  const totalRows = (settings.current.paging.totalRows - (Math.abs(tableDataLocal.length - queryResult.length)));
  settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, totalRows: totalRows } });
  tableData.current = queryResult;
  if(queryResult.length === 0) setRender(!render);
}

export function calcHeaderColour(direction, col, alpha = 4) {
  const orderDir = (direction && direction.orderDir) ? direction.orderDir.toLowerCase() : 'DIR_INVAL';
  const orderBy = (direction && direction.orderBy) ? direction.orderBy.toLowerCase() : 'BY_INVAL';
  const column = (col && col.value) ? col.value.toLowerCase() : 'COL_INVAL';
  if(orderDir === 'asc' && orderBy === column) return ` colour background-${alpha} grey `;
  if(orderDir === 'desc' && orderBy === column) return ` colour background-${alpha} grey `;
  return '';
}

export function calcPageSelect(settings, dataLength = 0) {

  const totalRows = (settings.current.paginationEnabled) ? (settings.current.paging.totalRows || 0) : dataLength;
  const pagingLimit = settings.current.paging.limit;
  const totalPages = Math.ceil(totalRows / pagingLimit || 0);
  const currentPage = (Number(totalPages) > Number(settings.current.paging.page)) ? Number(settings.current.paging.page) : 0;

  const showPages = 6;
  const pagesToShow = totalPages > showPages ? showPages : totalPages;

  let startPage = 0;
  if (totalPages > showPages && settings.current.paging.page > (showPages / 2) - 1) {
      startPage = settings.current.paging.page - (showPages / 2)
      if (startPage + showPages > totalPages - 1) {
          startPage = totalPages - showPages
      }
  }

  const totalPagesSelect = [];
  for(let i = 0; i < totalPages; i ++) {
    totalPagesSelect.push({ value: i, label: (i + 1) })
  }

  return { 
    totalRows: Number(totalRows), 
    pagingLimit: Number(pagingLimit), 
    totalPages: Number(totalPages), 
    totalPagesSelect: totalPagesSelect,
    pagesToShow: Number(pagesToShow), 
    startPage: Number(startPage),
    page: Number(currentPage)
  };
}

export function getDefaultSettings(header, tableName, defaultSettings) {

  const pagesMapped = (defaultSettings && defaultSettings.pages && Array.isArray(defaultSettings.pages))
    ? defaultSettings.pages.map(x => { return { label: x, value: x } })
    : [10,20,50,100,200].map(x => { return { label: x, value: x } });

  const seletedFilters = header.map(x => { return { name: x.value, type: x.type, value: '', filter: 1 } });

  const paging = (defaultSettings.paging) ? defaultSettings.paging : {};

  return {
    tableName: tableName,
    expanding: false,
    filtering: false,
    sortable: true,
    caching: false,
    filterEnabled: true, 
    refreshEnabled: true,
    paginationEnabled: true,
    showRecords: true,
    exportEnabled: true,
    expandEnabled: true,
    deleteEnabled: false,
    headerEnabled: true,
    expandData: { key: null, active: false },
    filters: seletedFilters,
    ...defaultSettings,
    paging: {
      pageSelected: 20, 
      limit: 20, 
      offset: 0, 
      totalRows: 0,
      page: 0,
      orderBy: (header && header[0]?.value) ? header[0].value : '', 
      orderDir: 'DESC',
      ...paging,
      orderClick: getOrderClick(paging),
      pages: pagesMapped
    }
  };
}

export function setCacheData(settings) {

  if(!settings.tableName || !settings.caching) return settings;

  const state = store.getState();
  const preferences = state.pagePreferences;
  const { dispatch } = store; 

  const baseData = (preferences[settings.tableName]) 
    ? { ...preferences[settings.tableName], ...settings }  
    : settings;

  dispatch(setPagePreferences({ ...preferences, [settings.tableName]: baseData }));

  return baseData;
}

export function getCacheData(tableName) {

  if(!tableName) return {};

  const state = store.getState();
  const preferences = state.pagePreferences;

  return (preferences[tableName]) ? preferences[tableName] : {};
}

export function getBaseData(header, tableName, defaultSettings, settings) {
  if(settings.current.caching) {
    return { ...getDefaultSettings(header, tableName, defaultSettings), ...getCacheData(tableName) };
  } else {
    return { ...settings.current, ...calcPageSelect(settings) };
  }
}

export function getOrderClick(data) {
  if(data.orderDir === 'ASC') return 2;
  if(data.orderDir === 'DESC') return 1;
  return 0;
}

