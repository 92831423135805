import { useState, useEffect, useRef, memo, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { getRegions } from 'classes/helpers';
import { statCard } from 'classes/statsCard'
import { permissionsObject, permissionDecider } from 'classes/permissions';
import UILIB from 'components';
import Axios from 'classes/axios';
import ContractsDealTable from './tables/contractsDealTable';
import InstallChecklistTable from '../logisticsHub/tables/installChecklistTable';
import { getDefaultFilters } from './functions';

const ContractsHub = memo((props) => {

  const tabs = [
    { label: "Contracts", value: 0 },
    { label: "Install Checklist", value: 1 } ];

  const location = useLocation();
  const account = useSelector((state) => state.account);
  const localPermissions = useRef({});
  const permissions = useSelector((state) => state.permissions);
  const permissionsData = useRef([{ name: 'contractsHub', hubName: 'Contracts Hub', routeName: location.pathname }]);
  const [topStats, setTopStats] = useState({ pending: 0, checked: 0, partial: 0, onHold: 0, completeNeedsContract: 0 });
  const [currentTab, setCurrentTab] = useState(0);
  const defaultfilters = getDefaultFilters(location);
  const [filters, setFilters] = useState(defaultfilters);
  const [statsRender, setStatsRender] = useState(false);
  const [regions, setRegions] = useState([]);
  const statContainerClass = 'cardGeneric';
  const statChildClass = 'cardGeneric cardChild';


  const handleChangefilters = (tabId) => {
    setCurrentTab(tabId);
    setFilters(defaultfilters); };

  useEffect(() => {
    const actionAsync = async () => {

      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      await props.permissions.generalAccess();

      const regionsResult = await getRegions();
      const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } });
      setRegions(regionsMapped);

    }
    actionAsync();
  }, []);

  useEffect(() => {
    const actionAsync = async () => {
      if(!filters.queryString) return;
      const quickStats = await Axios.get(`/entities/workflow/deals/actionGetTopStats/contracts/?&$filter=${filters.queryString}`);
      setTopStats({ ...topStats, ...quickStats.data.result });
    }
    actionAsync();
  }, [filters, statsRender]);


  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-item row width-100 mar-t5 mar-b10">
        {statCard(false, false, false, 'Contracts Pending', topStats.pending, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Contracts Checked', topStats.checked, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Contracts partially setup', topStats.partial, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Contracts on hold', topStats.onHold, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Logistics Complete needing contract', topStats.completeNeedsContract, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Complete Deals', topStats.complete, statContainerClass, statChildClass)}
      </div>
      <div className="flex-container row width-100">
        <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => handleChangefilters(newId)} />
        {currentTab === 0 && <ContractsDealTable
          filters={filters}
          setFilters={setFilters}
          statsRender={statsRender}
          setStatsRender={setStatsRender}
          defaultfilters={defaultfilters}
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions}
          route={location.pathname}
          regions={regions} />}
        {currentTab === 1 && <InstallChecklistTable
          regions={regions}
          route={location.pathname}
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
          dealId={0} />}
      </div>
    </div>
  </Fragment>
})
export default ContractsHub