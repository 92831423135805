
import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import axios from 'classes/axios';
import { formatCurrency } from 'classes/format';

async function getData() {
    const results = await axios.get('/sales/deals/graph');
    return results.data;
}

async function getNewCustomers() {
    const results = await axios.get('/sales/customers/newsummary');
    return results.data;
}

async function getNewOrders() {
    const results = await axios.get('/sales/deals/completesummary');
    return results.data;
}


export default function RowDealsView() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newCustomers, setNewCustomers] = useState(0);
    const [newOrders, setNewOrders] = useState({});

    useEffect(() => {
        getData().then(d => {
            setData(d);
            setLoading(false);
        })
        getNewCustomers().then(setNewCustomers);
        getNewOrders().then(setNewOrders);
    }, [])

    const results = [];
    let i = 12;
    const month = moment();
    while (i) {
        results.push({ month: month.utc().format('MM/YY'), booked: 0, invoiced: 0 });
        month.subtract(1, 'month');
        i--;
    }
    data.forEach(row => {
        const index = results.findIndex(r => r.month === moment(row.month, 'YYYY-MM').utc().format('MM/YY'));
        if (index > -1) {
            results[index].booked += row.booked;
            results[index].invoiced += row.invoiced;
        }
    })

    results.reverse();

    return <Fragment>
        <div className="flex-container row width-100">
          <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 mar-b15 ">
            <UILIB.Paper className="height-100 col">
              <div className="flex-container row">
                <div className="flex-item width-100">
                  <h2>Sales History - Booked</h2>
                </div>
                <div className="flex-item width-100" style={{ height: 250 }}>
                  {loading && <UILIB.Loading type={3} />}
                  {!loading && <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={results} margin={{ left: 20, right: 20 }}>
                      <XAxis dataKey="month" interval={0} />
                      <YAxis tickFormatter={(value) => value.toFixed(2)}></YAxis>
                      <Legend verticalAlign="top" />
                      <Line type="monotone" dataKey="booked" stroke="#E93D24" strokeWidth={2} activeDot={{ r: 6 }} />
                      <Line type="monotone" dataKey="invoiced" stroke="#000033" strokeWidth={2} activeDot={{ r: 6 }} />
                      <Tooltip formatter={(value, dataField) => { return formatCurrency(value) }} />
                    </LineChart>
                  </ResponsiveContainer>}
                </div>
              </div>
            </UILIB.Paper>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 mar-b15">
            <UILIB.Paper className="text-center flex-container row evenly height-100">
                <div className="flex-item center width-100">
                  <h3>{moment().utc().format('MMM YYYY')}</h3>
                </div>
                <div className="flex-item center width-100">
                  <h1 className="mar-b0">{newOrders.cnt}</h1>
                  <p className="text-grey text-small">Total Deals Booked</p>
                </div>
                <div className="flex-item center width-100">
                  <h1 className="mar-b0">{formatCurrency(newOrders.amt)}</h1>
                  <p className="text-grey text-small">Booked Gross Profit</p>
                </div>
                <div className="flex-item center width-100">
                  <h1 className="mar-b0">{newCustomers.cnt}</h1>
                  <p className="text-grey text-small">New Customers</p>
                </div>
            </UILIB.Paper>
          </div>
        </div>
    </Fragment>
}