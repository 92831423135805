import Modules from '../Modules';
import { useEffect, Fragment } from 'react';
import useExpandRows from "../Hooks/useExpandRows";

export default function TableSection({ 
  header, 
  row, 
  render, 
  moduleRender, 
  setModuleRender, 
  settings, 
  checked, 
  setChecked, 
  selectQuery, 
  rowIndex 
})
{

  const defaultState = Boolean(row[settings.current.expandData.key]?.raw?.isOpen);
  const { isOpen, toggle } = useExpandRows(defaultState);

  const canExpand = (settings.current.expandData.active & row.hasOwnProperty(settings.current.expandData.key));
  const dataLength = row[settings.current.expandData.key]?.raw?.entityData?.length;
  const enabled = (canExpand && dataLength > 0);

  const expand = 
    <div 
      onClick={(enabled) ? toggle : function () { }} 
      className="width-100 height-100"
      style={{ display: (enabled) ? 'initial' : 'none' }}>
      <div 
        className={`expand-rows up-arrow`} 
        style={{ 
          transform: `rotate(${isOpen ? 0 : 180}deg)`,
          transition: "all 0.25s" }}>
      </div>
    </div>

  useEffect(() => {
    setModuleRender(!moduleRender);
  }, [isOpen])

  if(canExpand) {
    row[settings.current.expandData.key] = { 
      value: expand, 
      raw: row[settings.current.expandData.key]?.raw 
    }; 
  }

  return <Fragment>
    <Modules.DataRow 
      onClick={(enabled) ? toggle : function () { }}
      key={`row_${rowIndex}`} 
      header={header} 
      row={row} 
      render={render} 
      moduleRender={moduleRender} 
      rowIndex={rowIndex} 
      expanding={settings.current.expanding} 
      checked={checked} 
      setChecked={setChecked} 
      selectQuery={selectQuery} /> 
      {Boolean(isOpen) && 
        <tr>     
          <td 
            colSpan={header.length}
            align="center">
            <div className="mar-0">
              <Modules.DataRowNested 
                entityData={row[settings.current.expandData.key]?.raw?.entityData} 
                headerData={row[settings.current.expandData.key]?.raw?.headerData} 
                tableSettings={row[settings.current.expandData.key]?.raw?.tableSettings}
                className={row[settings.current.expandData.key]?.raw.className} />
            </div>
          </td>
        </tr>}
  </Fragment> 
}