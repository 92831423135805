import EditSop from './modules/editSop';
import NoteCRMSync from './modules/noteCRMSync';
import ApprovedFiltersDrawer from './modules/approvedFiltersDrawer';
import AwaitingFiltersDrawer from './modules/awaitingFiltersDrawer';
import NotesAdd from '../shared/drawers/notesAdd';
import NotesDrawer from '../shared/drawers/notesDrawer';
import NotesView from '../shared/drawers/notesView';
import SendToLogistics from './modules/sendToLogistics';
import OptionsContainer from './modules/optionsContainer';
import SalesContainer from './modules/salesContainer';
import InvoiceContainer from './modules/invoiceContainer';
import PricingContainer from './modules/pricingContainer';
import MasterDealDrawer from './modules/masterDealDrawer';
import ReportAllData from './modules/reportAllData';
import ReportDeclinedData from './modules/reportDeclinedData';
import SopDrawer from './modules/sopDrawer';

const exported = {
  EditSop,
  NoteCRMSync,
  ApprovedFiltersDrawer,
  AwaitingFiltersDrawer,
  NotesAdd,
  NotesDrawer,
  NotesView,
  SendToLogistics,
  OptionsContainer,
  SalesContainer,
  InvoiceContainer,
  PricingContainer,
  MasterDealDrawer,
  ReportAllData,
  ReportDeclinedData,
  SopDrawer
}
export default exported;