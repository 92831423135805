import UILIB from 'components';
import moment from "moment";
import { setDrawer } from 'store/actions';
import DealModules from './Modules';
import { getLogisticsStatus } from 'classes/helpers';
import LogisticsModules from './Modules';

export function filterEngine(row, route) {

  const foundDeal = row.deal.find(x => Number(x.dealID) === Number(row.dealID)); 
  const isDisabled = ['/orderhub', '/sophub', '/contractshub'];

  const dataObject = {
    isDisabled: (!foundDeal && isDisabled.includes(String(route).toLowerCase()))
  }

  return { dataObject: dataObject };
};

export function setFilterVars(filter, location, query) {

  const ignoreFilters = (query.includes('partNo') && query.toLowerCase().includes('refinanced')) || (query.includes('newUsed') && query.toLowerCase().includes('retained'));
  const contractFilters = (location.pathname.includes('contractsHub') && !ignoreFilters) ? ' ( dealInvoiceDate isn null and ( ( partNo eq refinance or ( newUsed eq retained and isADevice eq 1 ) ) or ( serialNo isn null and pdiCompleted eq 1 and and productInvoiceDate is null and moveDate isn null and isADevice eq 1 ) ) ) ' : null;
  const filtersArray = [contractFilters].filter(x => x);

  if(filter.button === 'Active') {
    return [filtersArray, ` isADevice eq 1 and
      ( siteAddress is null 
        or invoiceNo is null 
        or moveDate is null
        or provInvoiceDate is null
        or serialNo is null ) `].join(' and ');
  }
  if(filter.button === 'Complete') {
    return ` isADevice eq 1 and
      ( siteAddress isn null 
        and invoiceNo isn null 
        and moveDate isn null
        and provInvoiceDate isn null 
        and serialNo isn null ) `;
  }
  if(filter.button === '') {
    return ' warehouse isn null and isADevice eq 1 ';
  }
}

export function setMainDealFilterVars(filters) {

  const regions = (filters.regionIds.length) ? ` salesRegion in ${filters.regionIds.join(',')} ` : null;
  const engStatus = (filters.engStatus !== -1) ? ` engStatus eq ${filters.engStatus} ` : null;
  
  const arrayData = [regions, engStatus].filter(x => x);

  return [...arrayData, ` ( status eq 1 and approved eq 1 and archived eq 0 ) `, 
    ` ( engStatus ne 10 and engStatus ne 7 and engStatus ne 25 ) `].join(' and ');
}

export function setDrawerFilterVars(filters, permission) {

  if(!permission) return null;

  const datesInvoiced = (filters.enabled) ? ` ( dealInvoiceDate gte ${moment(filters.datesInvoiced.startDate).utc().format('YYYY-MM-DD 00:00')} and dealInvoiceDate lte ${moment(filters.datesInvoiced.endDate).utc().format('YYYY-MM-DD 23:59')} ) ` : ' ';
  const newUsed = (filters.newUsed !== -1) ? ` ( newUsed eq ${filters.newUsed} ) ` : null;
  return [datesInvoiced, newUsed].filter(x => x).join(' and ');
}

export function getDefaultFilters() {
  return { 
    enabled: false,
    queryString: null,
    regionIds: [], 
    engStatus: -1,
    newUsed: -1, 
    datesInvoiced: {
      startDate: moment().subtract(10,"year").utc().format('YYYY-MM-DD').toString(),
      endDate: moment().utc().format('YYYY-MM-DD').toString()
    }
  };
}

export function getStatusChip(row) {
  if (row.status === 1 && row.approved === 1 && !row.provInvoiceDate) {
    return <UILIB.Chip toolTip="Awaiting Invoice" className="flex-container wrap table colour background-8 orange text-small width-100" value="Awaiting Invoice" />;
  }
  if (row.status === 1 && row.approved === 1 && row.provInvoiceDate) {
    return <UILIB.Chip toolTip="Invoiced" className="table colour background-8 green text-small width-100" value="Invoiced" />;
  }
  return <UILIB.Chip toolTip="Draft" className="table colour background-8 grey text-small width-100" value="Draft" />;  
}

export function getInstallStatusChip(row) {
  if (row.status === 1) {
    return <UILIB.Chip toolTip="Email Sent" className="flex-container wrap table colour background-8 green text-small width-100" value="Email Sent" />;
  }
  return <UILIB.Chip toolTip="Email Not Sent" className="flex-container wrap table colour background-8 grey text-small width-100" value="Email Not Sent" />;
}

export function calcResetEnable(filters, defaultfilters) {
  return Boolean(JSON.stringify(defaultfilters) === JSON.stringify({ ...filters, queryString: null }));
};

export function getDealRowColour(row) {
  if (row.daysActive > 7 && row.daysActive < 10) {
    return 'colour background-2 orange'
  }
  if (row.daysActive >= 10) {
    return 'colour background-2 red'
  }
}

export function getInvoicedSelect() {
  return [{ value: -1, label: "All" },{ value: "New", label: "New" },{ value: "Used", label: "Used" }];
}

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }];
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function actionOpenDrawer(type, entity, mergedEntity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewInstallDraw') return <LogisticsModules.InstallDrawer row={entity} mergedEntity={mergedEntity} isDisabled={!enabled} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'ViewNotesDrawer') return <DealModules.NotesDrawer entity={entity} allowAdd={'/logisticsHub'} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'InstallFiltersDrawer') return <LogisticsModules.InstallFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'DealFiltersDrawer') return <LogisticsModules.DealFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getShowFilters(route, account) {
  return Boolean((String(route).toLowerCase() === '/sophub' && (account && String(account.azureId) === '16d742dc-ff90-4edf-8ccc-8575b55120c2')));
};

export function getDefaultSelected(location) {
  return Boolean((location && (String(location.pathname).toLowerCase() !== '/sophub' && String(location.pathname).toLowerCase() !== '/orderhub')) || !location)
    ? 'Active'
    : null;
};

export function getNotesColour(row) {
  if (!row.dealNote.length) {
    return 'colour lightGrey'
  }
  if(row.dealNote.some(x => (x.noteSource.trim() === 'Internal Notes' || x.noteSource.trim() === 'External Notes')) && !row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 orange'
  }
  if(row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 red'
  }
}

export function calcLogisticsStatus(row) {
  const array = (row.provInvoiceDate)
    ? [0,1,2,3,4,5,6,7,10,15,30]
    : [0,1,2,3,4,5,6,7,15,30]
  return getLogisticsStatus(array)
    .map(status => { return { label: status.status, value: String(status.value) } })
    .sort((a, b) => a.value - b.value);
};

export function getSelect() {
  return getLogisticsStatus([0,1,2,3,4,5,6,7,10,15,30])
    .map(status => { return { label: status.status, value: String(status.value) } })
    .sort((a, b) => a.value - b.value);
};

export function buildAddress(row) {
  if(!row.addresses) return '';
  const { fromCRMId, id, dealId, telephone, telNo, county, customerName, accountNumber, createdAt, updatedAt, addressType, DeviceInstallationId, ...addressObj } = row.addresses;
  return addressObj ? Object.values(addressObj).filter(f => f && f.length).map(val => { return val }).join(', ') : '';
};