import UILIB from 'components';
import BlockCustomersOnStop from './blockCustomersOnStop';
import BlockServiceAlerts from './blockServiceAlerts';

export default function RowCustomersView() {
  const linksDefault = [
    { class: 'flex-item center width-100 text-12', link: 'https://360.agilico.co.uk/serviceadmin/customerportals/', title: 'Customer Portals' },
    { class: 'flex-item center width-100 text-12', link: 'https://360.agilico.co.uk/serviceadmin/customerlookup/', title: 'Customer Lookup' },
    { class: 'flex-item center width-100 text-12', link: 'https://360.agilico.co.uk/serviceadmin/customerinvoices/', title: 'Customer Invoices' },
    { class: 'flex-item center width-100 text-12', link: 'https://dmcplc365.sharepoint.com/sites/AgilicoInternalRentals2/_layouts/15/listform.aspx?PageType=8&ListId=%7B1AEB4595-C329-479B-822E-BAF385EAB4D2%7D&RootFolder=%2fsites%2fAgilicoInternalRentals2%2fLists%2fTickets&Source=https%3A%2f%2fdmcplc365.sharepoint.com%2fsites%2fAgilicoInternalRentals2%2fLists%2fTickets%2fAllItems.aspx&ContentTypeId=0x0100060D63929DA79B44884A0A837DB2F25E', title: 'Agilico Internal Rental Request' }]

  const linksConfigurator = [
    { class: 'flex-item center width-100 text-12', link: 'https://ricohconfigurator.com/', title: 'RICOH Online Configurator' },
    { class: 'flex-item center width-100 text-12', link: 'https://productconfigurator.konicaminolta.eu/eu/', title: 'Konica Minolta Configurator 2.0' },
    { class: 'flex-item center width-100 text-12', link: 'http://prod.c-oipsst.com/configurator-cs/configurator.html?region=CCAN&top=top1&lang=English&country=CANADA&reloaded=y', title: 'Canon ImageRUNNER ADVANCE Configurator' }]


  return <div className="row">
    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 mar-b15 ">
      <UILIB.Paper className="text-center height-100 flex-container row evenly">
        <h3 className="flex-item center width-100">Customer Links</h3>    
        <div className="mar-b5">{linksDefault.sort((a, b) => a.title.localeCompare(b.title)).map(x => { 
          return <UILIB.Button
                    className='primary small width-100'
                    value={x.title}
                    onClick={() => { window.open(`${x.link}`, '_blank') }}
                  />  
          })}
        </div>
        <div>{linksConfigurator.sort((a, b) => a.title.localeCompare(b.title)).map(x => { return <a className={x.class} href={x.link} rel="noreferrer" target="_blank"><div>{x.title}</div></a> })}</div>
      </UILIB.Paper>
    </div>
    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 mar-b15 ">
      <BlockServiceAlerts />
    </div>
    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 mar-b15 ">
      <BlockCustomersOnStop />
    </div>
  </div >
}