import Modules from './modules';
import { setDrawer } from 'store/actions';

export async function actionOpenKitFinderDrawer(type, kitData, dispatch, enabled) {
  if(!enabled) return false;

  const selector = (type, data) => {
    if(type === 'ViewKitFinderDrawer') return <Modules.KitFinderDrawer kitData={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} isActive={true} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: "600px",
    content: selector(type, kitData)
  }))
}