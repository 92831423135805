export const SIDELOAD_TYPES = {
  TYPE: 'SIDELOAD',
  ERROR: 'ERROR',
  PRODUCTS: 'product',
  COMPATIBLES: 'compatiblesRaw',
  DOCUMENTS: 'document',
  CRM: 'crm',
  PSUSERS: 'psUsers',
  DEVICENUMBERS: 'selected',
  HOSTCONFIG: 'hostConfig',
  OTHERDEALS: 'otherDeals',
  REMOVALS: 'removal',
  REMOVALSTORES: 'removalStore',
  UPDATEDEAL: 'updateDeal',
  PROCUREMENT: 'procurementData',
  DEALPRICING: 'pricing',
  KEYCONTACT: 'keyContact',
  NOTES: 'note',
  SETTLEMENT: 'settlement',
  ADDRESS: 'address',
  INVOICEADDRESS: 'invoiceAddress',
  DECLINETYPE: 'declineType'
}

export const DATA_TO_LOAD = {
  [SIDELOAD_TYPES.PRODUCTS]: false,
  [SIDELOAD_TYPES.COMPATIBLES]: false,
  [SIDELOAD_TYPES.DOCUMENTS]: false,
  [SIDELOAD_TYPES.CRM]: false,
  [SIDELOAD_TYPES.PSUSERS]: false,
  [SIDELOAD_TYPES.DEVICENUMBERS]: false,
  [SIDELOAD_TYPES.HOSTCONFIG]: false,
  [SIDELOAD_TYPES.OTHERDEALS]: false,
  [SIDELOAD_TYPES.REMOVALS]: false,
  [SIDELOAD_TYPES.REMOVALSTORES]: false,
  [SIDELOAD_TYPES.UPDATEDEAL]: false,
  [SIDELOAD_TYPES.PROCUREMENT]: false,
  [SIDELOAD_TYPES.DEALPRICING]: false,
  [SIDELOAD_TYPES.KEYCONTACT]: false,
  [SIDELOAD_TYPES.NOTES]: false,
  [SIDELOAD_TYPES.SETTLEMENT]: false,
  [SIDELOAD_TYPES.ADDRESS]: false,
  [SIDELOAD_TYPES.INVOICEADDRESS]: false,
  [SIDELOAD_TYPES.DECLINETYPE]: false
}

export const reducer = (state, action) => {
  switch (action.name) {
    case SIDELOAD_TYPES.ERROR:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [action.name]: false }
      }
    case SIDELOAD_TYPES.PRODUCTS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.PRODUCTS]: true },
        product: action.data.product,
        compatibles: action.data.product
      }
    case SIDELOAD_TYPES.REMOVALS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.REMOVALS]: true },
        removal: action.data
      }
    case SIDELOAD_TYPES.REMOVALSTORES:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.REMOVALSTORES]: true },
        removalStore: action.data
      }
    case SIDELOAD_TYPES.COMPATIBLES:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.COMPATIBLES]: true },
        compatiblesRaw: action.data
      }
    case SIDELOAD_TYPES.CRM:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.CRM]: true },
        crm: action.data
      }
    case SIDELOAD_TYPES.DOCUMENTS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.DOCUMENTS]: true },
        document: action.data
      }
    case SIDELOAD_TYPES.PSUSERS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.PSUSERS]: true },
        psUsers: action.data
      }
    case SIDELOAD_TYPES.DEVICENUMBERS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.DEVICENUMBERS]: true },
        selected: action.data
      }
    case SIDELOAD_TYPES.HOSTCONFIG:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.HOSTCONFIG]: true },
        hostConfig: action.data
      }
    case SIDELOAD_TYPES.OTHERDEALS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.OTHERDEALS]: true },
        otherDeals: action.data
      }
    case SIDELOAD_TYPES.UPDATEDEAL:
      return {
        ...state,
        ...action.data,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.UPDATEDEAL]: true }
      }
    case SIDELOAD_TYPES.PROCUREMENT:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.PROCUREMENT]: true },
        procurementData: action.data
      }
    case SIDELOAD_TYPES.DEALPRICING:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.DEALPRICING]: true },
        pricing: action.data
      }
    case SIDELOAD_TYPES.KEYCONTACT:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.KEYCONTACT]: true },
        keyContact: action.data
      }
    case SIDELOAD_TYPES.NOTES:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.NOTES]: true },
        note: action.data
      }
    case SIDELOAD_TYPES.SETTLEMENT:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.SETTLEMENT]: true },
        settlement: action.data
      }
    case SIDELOAD_TYPES.ADDRESS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.ADDRESS]: true },
        address: action.data
      }
    case SIDELOAD_TYPES.INVOICEADDRESS:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.INVOICEADDRESS]: true },
        invoiceAddress: action.data,
      }
    case SIDELOAD_TYPES.DECLINETYPE:
      return {
        ...state,
        dataLoaded: { ...state.dataLoaded, [SIDELOAD_TYPES.DECLINETYPE]: true },
        declineType: action.data,
      }
    default:
      return state;
  }
};

export const reduceArray = (data) => {
  return Object.keys(data).reduce((acc, obj) => ({ ...acc, [obj]: data[obj] }), {});
};



