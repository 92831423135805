import CustomerInventorySearch from "./modules/customerInventorySearch";
import CustomerInventoryTable from "./modules/customerInventoryTable";
import KitFinderDrawer from "./modules/kitFinderDrawer";

const exported = {
    CustomerInventorySearch,
    CustomerInventoryTable,
    KitFinderDrawer
  }
  
  export default exported;