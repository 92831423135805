import { useState, useRef, Fragment } from "react";
import Axios from "classes/axios";
import UILIB from "components";
import { formatDateTime } from "classes/format";
import { useDispatch } from "react-redux";
import { setFilterVarsUser, getGridChip, clearUserFilter, tableContainer, actionOpenDrawer } from '../functions';


export default function UserLookupTable({ selectedCustomer, setSelectedCustomer, defaultSelected, links }) {
  const headerData = [
    { label: '', value: 'nestedData', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: '', value: 'id', type: 'delete', behaviorType: 'single', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: 'Customer', value: 'customerName', type: 'string', filtering: true, minWidth: 300, maxWidth: 300 },
    { label: 'Account #', value: 'reference', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },  
    { label: 'Region', value: 'regionName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'First Name', value: 'firstName', type: 'string', filtering: true, minWidth: 110, maxWidth: 110 },
    { label: 'LastName', value: 'lastName', type: 'string', filtering: true, minWidth: 110, maxWidth: 110 },
    { label: 'Email Address', value: 'email', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },  
    { label: 'Last Online', value: 'lastOnDateTxt', type: 'date', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Login #', value: 'loginCount', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Privilege', value: 'isAdminTxt', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Source', value: 'source', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Status', value: 'statusTxt', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Invite', value: 'inviteStatus', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Login', value: 'options', type: 'string', filtering: false, minWidth: 70, maxWidth: 70 },
    { label: 'Edit', value: 'editUser', filtering: false, minWidth: 40, maxWidth: 40 }];


    const headerDataTest = [
      { label: "Group #", value: "groupCode", type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },  
      { label: 'First Name', value: 'firstName', type: 'string', filtering: false, minWidth: 110, maxWidth: 110 },
      { label: 'LastName', value: 'lastName', type: 'string', filtering: false, minWidth: 110, maxWidth: 110 },
      { label: 'Email Address', value: 'email', type: 'string', filtering: false, minWidth: 400, maxWidth: 400 }];


  const tablePageDefaults = { expandData: { key: 'nestedData', active: true }, deleteEnabled: true, nestedData: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'customerName', orderDir: 'ASC' } };
  const dispatch = useDispatch();
  const entityData = useRef({});
  const [filter, setFilter] = useState({ button: (selectedCustomer.deleted) ? 'Deleted' : 'Active' });
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [localRender, setLocalRender] = useState(false);
  const [remoteRender, setRemoteRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  const tableDeleteRequest = (event) => {
    setBannerError(defaultBanner);
    setShowDeleteDialoge({ ids: event, count: event.length, show: true });
  };

  const actionDeleteUser = async () => {
    try {
      setLoading(true);
      const requestIdArray = showDeleteDialogue.ids.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.id));
      const result = await Axios.delete('/entities/workflow/portals/actionDeleteUser/user', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const allowSelect = (filter.button !== 'Active');

      const fn_notes = actionOpenDrawer.bind(null, 'EditUserDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });

      const fn_viewCustomer = window.open.bind(null, `${links?.customerPortal}${row.reference}?actAs=${row.id}`,'_blank', 'noopener noreferrer');
      const viewCustomer = <UILIB.Button disabled={allowSelect} className={"button table text-small width-100 green"} value={'Login'} onClick={() => fn_viewCustomer()} />

      const subClass = 'colour background-4 limeGreen'

      const nestedData = tonderData.filter(x => x.reference === 'CONDOR').map(x => {
        return {
          firstName: { value: x.firstName, raw: x.firstName, class: subClass },
          lastName: { value: x.lastName, raw: x.lastName, class: subClass },
          groupCode: { value: x.groupCode, raw: x.groupCode, class: subClass },
          email: { value: x.email, raw: x.email, class: subClass }
        }
      })

      return {
        id: { value: row.id, raw: row.id, disabled: allowSelect },
        firstName: { value: row.firstName, raw: row.firstName },
        lastName: { value: row.lastName, raw: row.lastName },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        email: { value: row.email, raw: row.email },
        customerName: { value: row.customerName, raw: row.customerName },
        reference: { value: row.reference, raw: row.reference },
        regionName: { value: row.regionName, raw: row.regionName },
        lastOnDateTxt: { value: formatDateTime(row.lastOnDate), raw: formatDateTime(row.lastOnDate) },
        loginCount: { value: row.loginCount, raw: row.loginCount },
        source: { value: getGridChip('createSource', row.source, ), raw: row.source },
        isAdminTxt: { value: getGridChip('admin', row.isAdmin), raw: row.isAdminTxt },
        statusTxt: { value: getGridChip('user', row.status, row.isActive), raw: row.statusTxt },
        inviteStatus: { value: getGridChip('inviteStatus', row.inviteStatus), raw: row.inviteStatus },
        options: { value: viewCustomer, raw: 0 },
        editUser: { value: tableContainer(fn_notes, 'icon-pencil', 'Edit User', 'colour background-6 red', true), raw: true },
        nestedData: { 
          value: 0,
          raw: {
            nested: true, 
            expand: true, 
            entityData: nestedData, 
            headerData: headerDataTest, 
            tableSettings: { headerEnabled: false }
          }
        }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const expandArray = ['customer'];
    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsUser(selectedCustomer, filter);

    entityData.current = await Axios.get(`/entities/portalsV3_userbases/getSummary/?&$filter=${filterButtons} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Requests'}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count === 1) ? 'User' : 'Users' }`}
      subText={`NOTE! Deleting a user will also delete the Portal User from VO and the B2C binding from the Customer Portal`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteUser()}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container start wrap width-100'>
        <div className="flex-item start  flex-grow-1">
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
      </div>
      <div className="flex-item start width-100 mar-t5">
        {selectedCustomer.selected && <p className="flex-item start width-80 text-11">
          <i>Currently filtering as {selectedCustomer.customerName} - to clear the filter {clearUserFilter(defaultSelected, setSelectedCustomer, remoteRender, setRemoteRender)}</i></p>}
      </div>
      <div className='width-100'>
        <UILIB.TableNew
          name='PortalUserLookup'
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          deleteQuery={(ev) => tableDeleteRequest(ev)}
          localQuery={() => constructTable((entityData.current.result && entityData.current.result.length) ? entityData.current.result : [])}
          localRender={[localRender]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender, filter]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}
