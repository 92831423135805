import UILIB from 'components'
import { useState, useRef } from 'react';
import { exportEngine, getCancelToken } from '../functions'
import { exportData } from "classes/export";
import axios from 'axios'

export default function Export({ header, exportHeader, tableData, name, query, exportQuery, exportResponse, settings }) 
{

  const [loading, setLoading] = useState(false);
  const cancelToken = useRef(axios.CancelToken);
  const source = useRef(null);

  const handleExport = async (header) => {
    try {

      setLoading(true);

      const exportHeaderType = (exportHeader) ? exportHeader : header;

      const dataResult = (exportQuery) 
        ? await exportQuery()
        : await exportEngine(query, settings, getCancelToken(source, cancelToken));
        
      if(exportResponse) {
        exportResponse(dataResult)
      } else {
        exportData(exportHeaderType, dataResult.tableData, (settings && settings.tableName) ? settings.tableName : name)
      }

      setLoading(false);
    }
    catch (err) { 
      if(err !== 'ERR_CANCELED') console.log(err);
      if(err !== 'ERR_CANCELED') setLoading(false);
    }
  };

  const content = (loading) 
                    ? <UILIB.Loading type={3} className="-table" /> 
                    : <span className="icon-floppy-disk"></span>

  return <div title="Export" className={"export clickable"} onClick={async () => await handleExport(header, tableData.current)}>{content}</div>
}